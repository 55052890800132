import { jolokiaService, connectionService, databaseObjectService, mbeans } from '@/services'
import Instance from '@/models/Instance'
import Database from '@/models/Database'
import Schema from '@/models/Schema'
import Table from '@/models/Table'
import View from '@/models/View'
import Format from '@/models/Format'
import Storage from '@/models/Storage'
import Location from '@/models/Location'

const functionWhitelist = [
  'abs',
  'acos',
  'add_months',
  'age',
  'asin',
  'bit_length',
  'btrim',
  'cbrt',
  'ceil',
  'char_length',
  'chr',
  'concat_ws',
  'concat',
  'contains',
  'cos',
  'current_database',
  'current_date',
  'current_query',
  'current_schema',
  'current_timestamp',
  'current_user',
  'current_utc_timestamp',
  'date_part',
  'date_trunc',
  'dateadd',
  'datediff',
  'datename',
  'day',
  'days_between',
  'decrypt_ks',
  'decrypt',
  'degrees',
  'div',
  'encode',
  'encrypt_ks',
  'encrypt',
  'eomonth',
  'erf',
  'execution_id',
  'exp',
  'extract',
  'float4',
  'float8',
  'floor',
  'gen_random_uuid',
  'getdate',
  'hash',
  'hash4',
  'hash8',
  'hmac_ks',
  'hmac',
  'hostmask',
  'inet_merge',
  'initcap',
  'inject_idle',
  'instr',
  'int2',
  'int4',
  'int8',
  'ipv4_get_inet',
  'ipv4_get_maskbits',
  'ipv4_netmask',
  'ipv6_get_inet',
  'ipv6_get_maskbits',
  'ipv6_netmask',
  'justify_days',
  'justify_hours',
  'justify_interval',
  'last_day',
  'left',
  'length',
  'like',
  'ln',
  'localtime',
  'localtimestamp',
  'log',
  'lower',
  'lpad',
  'ltrim',
  'macaddr8_set7bit',
  'make_date',
  'make_interval',
  'make_time',
  'make_timestamp',
  'make_timestamptz',
  'md5',
  'mod',
  'month',
  'months_between',
  'next_day',
  'now',
  'nysiis',
  'octet_length',
  'overlaps',
  'pg_advisory_lock',
  'pg_advisory_unlock',
  'position',
  'power',
  'probnorm',
  'radians',
  'random',
  'regexp_extract',
  'regexp_instr',
  'regexp_like',
  'regexp_replace',
  'repeat',
  'replace',
  'reverse',
  'right',
  'round',
  'row_number_from_rowid',
  'rpad',
  'rtrim',
  'session_user',
  'sign',
  'sin',
  'split_part',
  'sqrt',
  'strpos',
  'substr',
  'substring',
  'text',
  'timeofday',
  'timezone',
  'to_ascii',
  'to_char',
  'to_date',
  'to_hex',
  'to_number',
  'to_timestamp',
  'translate',
  'trim',
  'trunc',
  'trunc',
  'upper',
  'version',
  'worker_id_from_rowid',
  'worker_id',
  'worker_uuid',
  'yb_terminate_session',
  'year'
]

export default {
  getDatabases(model) {
    const editor = model.__yb_editor
    if (editor) {
      return Database.query().where('instance_id', editor.settings.instance).get()
    } else {
      return []
    }
  },
  getSchemas(model) {
    const editor = model.__yb_editor
    if (editor) {
      return Schema.query().where('instance_id', editor.settings.instance).where('database_id', editor.settings.database_id).get()
    } else {
      return []
    }
  },
  async getTables(model, target) {
    false && console.info('getting tables for', target)
    const editor = model.__yb_editor
    if (editor) {
      let { database: databaseTarget, database_id: databaseTargetId, instance } = editor.settings
      let databaseObjects = []

      // Special case #1.  Is the target a hit on database name?  If so, return objects from that database.
      if (!!target && typeof target === 'string') {
        const targetParts = target.split(/\./g)
        const [databaseTargetCandidate] = targetParts
        if (this.getDatabases(model).find(d => d.name === databaseTargetCandidate)) {
          // Re-orient target and database to search within.
          databaseTarget = databaseTargetCandidate
          target = targetParts.length > 1 ? targetParts.slice(1).join('.') : target

          // Populate this database.
          if (instance) {
            await databaseObjectService.populate(instance, databaseTarget)
          } else {
            console.warn('Could not populate database for ', databaseTarget, 'because instance', instance, 'was not found')
          }

          // Find the target database object's id.
          const databaseTargetObject = Database.query().where('instance_id', instance).where('name', databaseTarget).first()
          if (databaseTargetObject) {
            databaseTargetId = databaseTargetObject.database_id
          }

          // Look for schemas within this database model for this database.
          if (targetParts.length === 1) {
            databaseObjects = Schema.query().where('instance_id', instance).get()
              .filter(schema => schema.database_id === databaseTargetId)
          }
        }
      }

      // Have a schema?
      const schemaFilter = _ => !target
      const targetRE = !!target && new RegExp(`^${target.replace(/\./g, '/')}$`)
      let objectFilter = target ? obj => obj.schema_name === target || obj.path.match(targetRE) : obj => true

      // Add search path filter.
      const { searchPath } = editor.settings
      if (!!searchPath && !target) {
        const path = new Set(searchPath)
        const scopedObjectFilter = target ? objectFilter : obj => false
        const pathedObjectFilter = obj => scopedObjectFilter(obj) || path.has(obj.schema_name)
        objectFilter = pathedObjectFilter
      }

      // Do the query based on current database for all objects.
      const selectedDatabaseObjects = []
        .concat(...Schema.query().where('instance_id', instance).get()
          .filter(schema => schema.database_id === databaseTargetId)
          .filter(schemaFilter)
        )
        .concat(...[ // synthetic: available cross-db from sys.schema
          { database: databaseTarget, name: 'const', schema: 'sys', type: 'table', path: databaseTarget + '/sys' }
        ]
          .filter(table => table.database_id === databaseTargetId)
          .filter(objectFilter)
        )
        .concat(...Table.query().where('instance_id', instance).get()
          .filter(table => table.database_id === databaseTargetId)
          .filter(objectFilter)
        )
        .concat(...View.query().where('instance_id', instance).get()
          .filter(view => view.database_id === databaseTargetId)
          .filter(objectFilter)
        )

      return databaseObjects.concat(...selectedDatabaseObjects)
    } else {
      console.warn('Returning empty table list because no editor context could be found')
      return []
    }
  },
  async getColumns(model, tableParts) {
    const result = []

    const editor = model.__yb_editor
    if (editor) {
      const database = editor.settings.database
      const database_id = editor.settings.database_id
      const instance = editor.settings.instance

      if (!!tableParts && !!tableParts.length) {
        // Resolve object: table or a view
        let tableOrView

        // Need to find table by search path.
        if (tableParts.length === 1) {
          // Have a search path?  Use that to filter/find in order.
          const searchPath = editor.settings.searchPath
          if (searchPath) {
            for (const path of searchPath) {
              const filter = item => item.schema_name === path
              const found = []
                .concat(...Table.query().where('instance_id', instance).get()
                  .filter(table => table.database_id === database_id)
                  .filter(filter)
                )
                .concat(...View.query().where('instance_id', instance).get()
                  .filter(view => view.database_id === database_id)
                  .filter(filter)
                )
                .find(item => item.name === tableParts[0])
              if (found) {
                tableOrView = found
                break
              }
            }
          }
        } else {
          // We can support direct object lookup easily with separated parts
          if (tableParts.length === 3) {
            tableOrView = Table.query().where('instance_id', instance).get()
              .filter(table => table.database_name === tableParts[0])
              .filter(table => table.schema_name === tableParts[1])
              .filter(table => table.name === tableParts[2])
          } else if (tableParts.length === 2) {
            tableOrView = Table.query().where('instance_id', instance).get()
              .filter(table => table.database_name === database)
              .filter(table => table.schema_name === tableParts[0])
              .filter(table => table.name === tableParts[1])
          }

          // Did we find a table?
          if (tableOrView?.length === 1) {
            tableOrView = tableOrView[0]
          } else {
            tableOrView = null
          }
        }

        // Have table?
        if (tableOrView) {
          if (tableOrView.type === 'view') {
            const sql = `select c.column_name || '' as name
              from information_schema.tables t
              left join information_schema.columns c
              on t.table_schema = c.table_schema
              and t.table_name = c.table_name
              where t.table_type = 'VIEW'
              and t.table_name = '${tableOrView.name}'
              and t.table_schema = '${tableOrView.schema_name}'`
            const result = await jolokiaService.execute(mbeans.datasources, 'queryEx', [database, sql, null, 0])
            return result.rows
          } else {
            const result = await jolokiaService.execute(mbeans.datasources, 'queryEx', [database, `select column_id, name, 'column' as type from sys.column where table_id = ${tableOrView.table_id}`, null, 0])
            return result.rows
          }
        }
      }
    }

    return result
  },

  async getFunctions(model, schema) {
    const result = []

    const editor = model.__yb_editor
    if (editor) {
      const { database, instance } = editor.settings

      // Use previous value if we computed it.
      if (!editor.functions) {
        // TODO: should we just use Function model?

        // Connect.
        const instanceObject = Instance.query().whereId(instance).first()
        if (instanceObject) {
          await connectionService.connect(instanceObject.id)
        }

        // Lookup functions (filtered)
        const sql = `select distinct(r.routine_name || '') as name, r.routine_schema || '' as schema_name
              from information_schema.routines r
              where r.routine_type = 'FUNCTION'
              and (
                r.routine_schema not in ('pg_catalog', 'information_schema', 'sys') or
                r.routine_name in (${functionWhitelist.map(t => "'" + t + "'").join(',')})
              )`
        const result = await jolokiaService.execute(mbeans.datasources, 'queryEx', [database, sql, null, 0])
        editor.functions = result.rows.map((func) => {
          if (func.schema_name !== 'pg_catalog') {
            func.name = `${func.schema_name}.${func.name}`
          }
          return func
        })
      }

      return editor.functions
    }

    return result
  },

  map(editor, item) {
    const { instance, database_id } = editor.settings
    const schema = Schema.query().whereId([instance, database_id, item.schema_id]).first()
    if (schema) {
      item.label = `${schema.name}.${item.name}`
    } else {
      item = null
    }
    return item
  },

  nonNull(item) {
    return !!item
  },

  async getLocations(model, schema) {
    const editor = model.__yb_editor
    if (editor) {
      const { instance } = editor.settings
      return Location.query().where('instance_id', instance).get()
    }
    return []
  },

  async getStorage(model, schema) {
    const editor = model.__yb_editor
    if (editor) {
      const { instance } = editor.settings
      return Storage.query().where('instance_id', instance).get()
    }
    return []
  },

  async getFormats(model, schema) {
    const editor = model.__yb_editor
    if (editor) {
      const { instance } = editor.settings
      return Format.query().where('instance_id', instance).get()
    }
    return []
  },

  async errorHandler(model, errors) {
    const editorComponent = model.__yb_editorComponent
    if (editorComponent) {
      const { monaco } = editorComponent

      monaco.editor.setModelMarkers(model, "yb", !errors
        ? null
        : errors.map((error) => {
          let message = 'Syntax error'
          const expected = error.expected
            .map(err => err.replace(/^'(.*?)'$/, '$1'))
            .join(', ')
          if (expected) {
            message += `, expected: ${expected}`
          }
          return {
            startLineNumber: error.loc?.first_line,
            endLineNumber: error.loc?.last_line,
            startColumn: error.loc?.first_column + 1,
            endColumn: error.loc?.last_column + 1,
            severity: monaco.MarkerSeverity.Error,
            message
          }
        }))
    }
  }
}
