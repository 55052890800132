<template>
  <div
    v-tooltip="{
      content: modelValue,
      delay: { show: 500, hide: 0 }
    }"
    class="yb-tab h-8 relative flex justify-items-center items-center yb-border-content-editor cursor-pointer"
    :class="{
      'bg-yb-gray-faint font-semibold dark:bg-yb-gray-mediumer': active,
      'bg-transparent pt-1 border-t border-l border-r hover:bg-yb-gray-fainter dark:hover:bg-yb-gray-light dark:hover:text-yb-black': !active,
    }"
    @dblclick="dblclick"
    @click="click"
  >
    <div v-if="active" class="border-t-2 border-yb-gray-medium dark:border-yb-brand-primary absolute h-1 w-full top-0" />
    <div v-if="active" class="bg-yb-gray-faint absolute h-1 w-full dark:bg-yb-gray-mediumer" style="bottom: -1px;" />

    <div v-show="!editing" class="text-sm ml-4 mr-8 truncate whitespace-nowrap" :style="labelStyle">
      {{ modelValue }}
    </div>
    <form v-show="editing" @submit.prevent="change">
      <input
        ref="editor"
        v-model="editValue"
        class="whitespace-nowrap text-sm font-semibold ml-4 mt-0 w-24 yb-input-color"
        maxlength="50"
        @blur="change"
        @keydown.escape.prevent.stop="escape"
      >
    </form>

    <div class="p-3 absolute top-0 right-0" @click.prevent.stop="close">
      <yb-svg-close class="w-2 h-2 stroke-current" />
    </div>
  </div>
</template>

<script>
import Draggabilly from 'draggabilly'
import YbSvgClose from '@/assets/svg/close.svg'

export default {
  components: {
    YbSvgClose
  },
  props: {
    tabId: String,
    modelValue: String,
    active: Boolean
  },
  emits: ['update:modelValue', 'dragMove', 'dragEnd', 'close', 'click'],
  data() {
    return {
      editing: false,
      editValue: null
    }
  },
  computed: {
    labelStyle() {
      if (!this.active) {
        return {
          marginTop: '-0.5rem'
        }
      } else {
        return {}
      }
    }
  },
  mounted() {
    // Setup draggabilly for tab drag/drop.
    this._dragabilly = new Draggabilly(this.$el, {
      axis: 'x',
      containment: true
    })
    this._dragabilly.on('dragMove', this.dragMove.bind(this))
    this._dragabilly.on('dragEnd', this.dragEnd.bind(this))

    // For backward compatabilitiy with vue 2, emulate __vue__
    this.$el.__vue__ = this
  },
  beforeUnmount() {
    if (this._dragabilly) {
      this._dragabilly.destroy()
      this._dragabilly = null
    }
  },
  methods: {
    dblclick(event) {
      const { editor } = this.$refs
      if (!editor) {
        return
      }
      this.editValue = this.modelValue
      this.editing = true
    },
    escape(event) {
      this.editing = false
    },
    change(event) {
      if (!this.editing) {
        return
      }
      this.editing = false
      if (this.modelValue !== this.editValue && this.editValue) {
        this.$emit('update:modelValue', this.editValue)
      }
    },
    close(event) {
      this.$emit('close', this)
    },
    dragMove($event) {
      this.$emit('dragMove', $event, this.$el)
    },
    dragEnd($event) {
      this.$emit('dragEnd', $event, this.$el)
    },
    click($event) {
      this.$emit('click', $event)
    }
  }
}
</script>

<style scoped lang="postcss">
.yb-tab {
  @apply flex-shrink;
  min-width: 75px;
  width: 150px;
  max-width: 250px;
  margin-left: -1px;
}

.yb-tab.is-dragging {
  @apply z-10 ml-1;
}
</style>
