<template>
  <div class="relative">
    <div class="flex relative" :data-test-id="dataTestId">
      <div ref="button" :class="buttonClass" @click="toggleOpen">
        <yb-icon v-if="icon" :class="iconClass" :icon="icon" />
        <span v-if="label">{{ label }}</span>
        <slot v-else name="label" />
        <div v-if="openClickArrow" class="inline-block ml-2 mr-1 h-5 bg-white dark:bg-yb-gray-light" style="width: 1px;" @click="arrowClick" />
        <div class="h-full flex items-center" @click="arrowClick">
          <div v-if="showArrow" class="w-4 h-4 leading-normal yb-center">
            <yb-icon class="yb-button-icon-sm w-2 stroke-0 fill-current dark:text-yb-gray-medium" icon="nav-arrow-down" />
          </div>
        </div>
      </div>
    </div>

    <div
      ref="dropdown"
      v-on-clickaway="close"
      class="mt-0.5 p-0 absolute z-10 rounded-sm py-1 border border-yb-gray-lightest dark:border-yb-gray-medium bg-white text-yb-gray-medium dark:bg-yb-gray-darkest dark:text-yb-gray-faintest shadow-lg select-none max-w-md"
      style="{ top: 100%; }"
      :class="dropdownClass"
      @click="dropdownClick"
    >
      <div v-if="multiple" class="flex flex-col overflow-y-auto" :class="maxHeight">
        <div v-if="links" class="flex flex-row text-sm px-2 pt-1">
          Select:&nbsp;&nbsp;
          <a v-for="(link, index) in links" :key="index" class="px-1 text-sm yb-link hover:underline" href="#" @click="click(link.id)">{{ link.label }}</a>
        </div>
        <div class="flex flex-row flex-wrap p-1">
          <div v-for="(item, index) in multiple" :key="index" class="inline-block m-0.5 p-0.5 pr-4 border border-yb-brand-primary bg-yb-selection-light dark:text-yb-black rounded-sm relative text-xs">
            {{ item }}
            <div class="px-1 absolute right-0 cursor-pointer" style="top: calc(50% - 0.25rem)" @click.prevent.stop="click('item', item)">
              <yb-svg-close class="w-2 h-2 stroke-current" />
            </div>
          </div>
        </div>
        <hr>
      </div>
      <div class="overflow-y-auto" :class="maxHeight" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import { directive as OnClickaway } from 'vue3-click-away'
import YbButtonMixin from './YbButtonMixin'
import YbSvgClose from '@/assets/svg/close.svg'

export default {
  components: {
    YbSvgClose
  },
  directives: {
    OnClickaway
  },
  mixins: [YbButtonMixin],
  props: {
    openClickArrow: Boolean,
    showArrow: {
      type: Boolean,
      default: true
    },
    dropdownClasses: String,
    iconClasses: String,
    alignRight: Boolean,
    multiple: Array,
    links: Array,
    maxHeight: {
      type: String,
      default: 'max-h-300'
    }
  },
  emits: ['click', 'select'],
  data() {
    return {
      open: false
    }
  },
  computed: {
    dropdownClass() {
      const result = []
      const { dropdownClasses } = this
      if (dropdownClasses) {
        result.push(dropdownClasses)
      }
      if (this.open) {
        result.push('block')
      } else {
        result.push('hidden')
      }
      return result
    }
  },
  methods: {
    toggleOpen($event) {
      if (this.disabled) {
        return
      }
      if (!this.openClickArrow) {
        this.open = !this.open
      } else {
        this.$emit('click', $event)
      }
    },
    arrowClick($event) {
      if (!this.disabled && this.openClickArrow) {
        this.open = !this.open
        $event.preventDefault()
        $event.stopPropagation()
      }
    },
    close($event) {
      if (this.multiple) {
        // Check event target.  If its inside our popdown, ignore.
        const { dropdown } = this.$refs
        let walk = $event?.target
        while (walk) {
          if (walk === dropdown) {
            return
          }
          walk = walk.parentNode
        }
      } else {
        // Check event target.  If its our button, ignore.
        const { button } = this.$refs
        let walk = $event?.target
        while (walk) {
          if (walk === button) {
            return
          }
          walk = walk.parentNode
        }
      }
      this.open = false
    },
    click(verb, item) {
      this.$emit('select', verb, item)
      if (!this.multiple) {
        this.close()
      }
    },
    dropdownClick() {
      if (!this.multiple) {
        this.open = false
      }
    },
    hide() {
      this.open = false
    }
  }
}
</script>
