<template>
  <yb-drop-down-button v-bind="$attrs" label="Edit" icon="edit" max-height="h-auto" action-link>
    <yb-drop-down-key command="undo" label="Undo" @click="click" />
    <yb-drop-down-key command="redo" label="Redo" @click="click" />
    <hr>
    <yb-drop-down-key command="cut" label="Cut" @click="click" />
    <yb-drop-down-key command="copy" label="Copy" @click="click" />
    <yb-drop-down-key v-if="!firefox" command="paste" label="Paste" @click="click" />
    <yb-drop-down-key command="yb.edit.clear" label="Clear" @click="click" />
    <hr>
    <yb-drop-down-key command="editor.action.selectAll" label="Select All" @click="click" />
    <hr>
    <yb-drop-down-key command="actions.find" label="Find" @click="click" />
    <yb-drop-down-key command="editor.action.startFindReplaceAction" label="Find/Replace" @click="click" />
    <yb-drop-down-key command="editor.action.gotoLine" label="Go To Line" @click="click" />
    <yb-drop-down-key command="command.cursorTop" label="Go To Top" @click="click" />
    <yb-drop-down-key command="command.cursorBottom" label="Go To Bottom" @click="click" />
    <hr>
    <yb-drop-down-key command="deleteAllLeft" label="Delete All Left" @click="click" />
    <yb-drop-down-key command="deleteAllRight" label="Delete All Right" @click="click" />
    <yb-drop-down-key command="editor.action.deleteLines" label="Delete Lines" @click="click" />
    <yb-drop-down-key command="editor.action.joinLines" label="Join Lines" @click="click" />
  </yb-drop-down-button>
</template>

<script>
import YbDropDownKey from './DropDownKey.vue'

export default {
  components: {
    YbDropDownKey
  },
  emits: ['click'],
  computed: {
    firefox() {
      return !!navigator.userAgent.match(/firefox/i)
    }
  },
  methods: {
    click(id) {
      this.$emit('click', id)
    }
  }
}
</script>
