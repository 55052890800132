<template>
  <div>
    <vue-context v-if="item" ref="menu" class="yb-v-context">
      <div class="yb-menu-heading">
        <yb-icon class="yb-button-icon" icon="file-details" />
        Details
      </div>
      <li>
        <a class="indent" @click.prevent="open">View Details</a>
      </li>
      <div class="yb-menu-heading">
        <yb-icon class="yb-button-icon" icon="action" />
        Actions
      </div>
      <li>
        <a class="indent" @click.prevent="load">Load Data</a>
      </li>
      <div class="yb-menu-heading">
        <yb-icon class="yb-button-icon" icon="file-search" />
        Explore
      </div>
      <li>
        <a class="indent" data-test-id="explore-data" @click.prevent="explore">Data</a>
      </li>
      <li>
        <a class="indent" data-test-id="explore-relationships" @click.prevent="relationships">Relationships</a>
      </li>
      <div class="yb-menu-heading">
        <yb-icon class="yb-button-icon" icon="file-copy" />
        Copy
      </div>
      <li>
        <a v-yb-clipboard="copyPathToClipboard()" class="indent">Path to Clipboard</a>
      </li>
      <li>
        <a class="indent" @click.prevent="copyPathToEditor">Path to Editor</a>
      </li>
      <div class="yb-menu-heading">
        <yb-icon class="yb-button-icon" icon="file-editor" />
        Editor
      </div>
      <li>
        <a class="indent" @click.prevent="append">Append</a>
      </li>
      <li>
        <a class="indent" @click.prevent="replace">Replace</a>
      </li>
      <li>
        <a class="indent" @click.prevent="query">New</a>
      </li>
    </vue-context>

    <transition name="drawer">
      <yb-modal
        v-if="showDetails"
        show
        :title="'Details for ' + path"
        icon="table"
        type="form"
        layout="drawer"
        dialog-classes=""
        drawer-width="yb-side-md"
        data-test-id="table-details"
        @close="showDetails = false"
      >
        <yb-table
          :id="id"
          :database="databaseName"
          :name="name"
          :schema="schemaName"
          :instance-id="instanceId"
          :details="details"
        />
      </yb-modal>
    </transition>

    <transition name="drawer">
      <yb-modal
        v-if="showRelationships"
        show
        :title="'Relationships for Table ' + schemaName + '.' + name"
        icon="schema"
        type="form"
        layout="drawer"
        dialog-classes=""
        drawer-width="w-2/3"
        data-test-id="table-relationships"
        @close="showRelationships = false"
      >
        <yb-detail-relationships :database="databaseName" :schema-id="schemaId" :table-id="id" />
      </yb-modal>
    </transition>
  </div>
</template>

<script>
import YbDetailRelationships from './DetailRelationships.vue'
import YbEditorMixin from './EditorMixin'
import YbItemMixin from './TableMixin'
import YbTable from '@/app/table-details/Table.vue'
import VueContext from '@/lib/vue-context/js'

export default {
  components: {
    VueContext,
    YbTable,
    YbDetailRelationships
  },
  mixins: [YbEditorMixin, YbItemMixin],
  data() {
    return {
      item: null,
      showDetails: false,
      showRelationships: false
    }
  },
  methods: {
    menu(item, $event) {
      this.item = item
      window.setTimeout(() => this.$refs.menu.open($event), 100)
    },

    closeMenu() {
      this.$refs.menu && this.$refs.menu.close()
    },

    async open() {
      await this.update()
      this.showDetails = true
    },

    query() {
      this.$emit('query', { databaseName: this.databaseName, schemaName: this.schemaName, tableName: this.name })
    },

    explore() {
      this.$emit('explore', { databaseName: this.databaseName, schemaName: this.schemaName, tableName: this.name, path: this.path })
    },

    copyPathToClipboard() {
      return this.path
    },

    copyPathToEditor() {
      this.$emit('execute', { id: 'paste', payload: { text: this.path } })
    },

    append() {
      // Advance to end.
      this.$emit('execute', { id: 'command.cursorBottom' })

      // Paste the query to end of sql.
      const currentSQL = String(this.editor.settings.sql).trim()
      let appendQuery = ''
      if (!!currentSQL && !currentSQL.match(/;$/)) {
        appendQuery += ';\n'
      }
      appendQuery += `SELECT * FROM ${this.path} LIMIT 1000;\n`
      this.$emit('execute', { id: 'paste', payload: { text: appendQuery } })
    },

    replace() {
      this.$emit('execute', { id: 'yb.edit.clear' })
      this.append()
    },

    add(_, semi) {
      let sql = this.editor.settings.sql?.trim() || ''
      if (semi && !!sql) {
        sql += ';'
      }
      sql += _
      this.editor.settings.sql = sql
    },

    relationships() {
      this.showRelationships = !this.showRelationships
    },

    load() {
      const instanceId = encodeURIComponent(this.instanceId)
      const database = encodeURIComponent(this.databaseName)
      const tableSchema = encodeURIComponent(this.schemaName)
      const table = encodeURIComponent(this.name)
      this.$router.push(`/data-wizard/select-data?instanceId=${instanceId}&database=${database}&tableSchema=${tableSchema}&table=${table}`)
    }
  }
}
</script>
