<template>
  <yb-drop-down-button label="Action" icon="action" v-bind="$attrs" max-height="h-auto" action-link>
    <yb-drop-down-key command="editor.action.quickCommand" label="Show Commands" @click="click" />
    <yb-drop-down-key command="yb.action.history" label="Show History" @click="click" />
    <yb-drop-down-key command="yb.action.search" label="Search Database Objects" @click="click" />
    <hr>
    <yb-drop-down-key data-test-id="fullscreen" command="yb.action.fullscreen" label="Fullscreen" @click="click" />
    <yb-drop-down-key data-test-id="zenmode" command="yb.action.zoom" label="Zoom Editor (Zen Mode)" :selected="editor.settings.zoomed" @click="click" />
    <yb-drop-down-key command="yb.action.schemaBrowser" label="Show schema Browser" :selected="editor.settings.schemaBrowser" @click="click" />
    <hr>
    <yb-drop-down-key command="yb.action.reformat" label="Reformat" @click="click" />
    <yb-drop-down-key command="editor.action.transformToUppercase" label="Transform Uppercase" @click="click" />
    <yb-drop-down-key command="editor.action.transformToLowercase" label="Transform Lowercase" @click="click" />
    <hr>
    <yb-drop-down-key command="editor.action.indentLines" label="Indent Lines" @click="click" />
    <yb-drop-down-key command="editor.action.outdentLines" label="Outdent Lines" @click="click" />
    <hr>
    <yb-drop-down-key command="editor.action.sortLinesAscending" label="Sort Lines Ascending" @click="click" />
    <yb-drop-down-key command="editor.action.sortLinesDescending" label="Sort Lines Descending" @click="click" />
  </yb-drop-down-button>
</template>

<script>
import YbDropDownKey from './DropDownKey.vue'
import YbEditorMixin from './EditorMixin'

export default {
  components: {
    YbDropDownKey
  },
  mixins: [YbEditorMixin],
  emits: ['click'],
  methods: {
    click(id) {
      this.$emit('click', id)
    }
  }
}
</script>
