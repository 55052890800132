<template>
  <yb-drop-down-item
    class="relative"
    :disabled="disabled"
    :selected="selected"
    check-selected
    :text-class="textClass"
    :link-class="linkClass"
    @click="click"
  >
    <template #default>
      <div v-if="label" style="min-width: 20em;" :class="{'yb-disable-text': disabled}">
        {{ label }}
      </div>
      <slot v-else />
    </template>
    <template #righthand>
      <div ref="keybinding" class="dropdown-key" :class="{'yb-disable-text': disabled}">
        {{ keyLabel }}
      </div>
    </template>
  </yb-drop-down-item>
</template>

<script lang="ts">
import YbEditorMixin from './EditorMixin'
import * as monacoApi from '@/components/monaco'

export default {
  mixins: [YbEditorMixin],
  props: {
    command: {
      type: String,
      required: true
    },
    textClass: String,
    linkClass: String,
    label: String,
    disabled: Boolean,
    selected: Boolean
  },
  emits: ['click'],
  data() {
    return {
      keyLabel: ''
    }
  },
  watch: {
    'editor.editorInstanceId': function editorInstanceIdChange(_) {
      _ && this.computeKeyLabel()
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.computeKeyLabel()
      if (!this.keyLabel) {
        monacoApi.registerKeybindingComponent(this)
      }
    })
  },
  beforeUnmount() {
    monacoApi.unregisterKeybindingComponent(this)
  },
  methods: {
    computeKeyLabel() {
      if (this.keyLabel) {
        return
      }
      if (monacoApi) {
        const command = this.command.match(/^yb/)
          ? this.editor?.editorInstanceId + ':' + this.command
          : this.command.match(/^command\./)
            ? this.command.substring('command.'.length)
            : this.command
        const label = monacoApi.lookupKeybinding(command)
        if (label) {
          false && console.log('bound command', command, 'as key label', label)
          this.keyLabel = label
        } else {
          false && console.log('could not bind command', command)
        }
      }
    },
    click() {
      this.$emit('click', this.command)
    }
  }
}

</script>

<style scoped>
.dropdown-key {
  @apply absolute top-0 right-0 mt-1 mr-2;
}
</style>
