<template>
  <yb-text-editor
    ref="textEditor"
    v-model="editor.settings.sql"
    class="w-full h-full"
    language="ybsql"
    :disabled="editor.loading"
    @editor-will-mount="editorWillMount"
    @editor-did-mount="editorDidMount"
    @selected="editorSelected"
  />
</template>

<script>
import debounce from 'debounce'
import sqlFormatter from 'sql-formatter'

import editorResourceProvider from './EditorResourceProvider'
import * as api from '@/components/monaco/api'
import { provideCompletionItemsFromModel } from '@/components/monaco/lang/ybsql'

// Hook in our resource provider.
api.setResourceProvider(editorResourceProvider)

export default {
  inject: ['editor'],
  emits: ['ready', 'execute', 'selected', 'change'],
  watch: {
    'editor.settings.database': function(_) {
      this.hasConnection && this.hasConnection.set(!!_)
    },
    'editor.loading': function(_) {
      this.hasQuery && this.hasQuery.set(!!_)
    },
    'editor.settings.sql': function(_) {
      this.syntaxCheck()
      this.$emit('change', _)
    }
  },
  beforeMount() {
    this.syntaxCheck = debounce(this.syntaxCheckImpl.bind(this), 500)
  },
  methods: {
    editorWillMount(monaco) {
      this.monaco = monaco
    },
    editorDidMount(editor) {
      this.editorInstance = editor
      this.editor.editorInstanceId = editor.getId()

      // Setup custom keybindings.

      const { monaco } = this
      this.hasConnection = editor.createContextKey('hasConnection', !!this.editor.settings.database)
      this.hasQuery = editor.createContextKey('hasQuery', !!this.editor.loading)
      let order = 1
      editor.addAction({
        id: 'yb.run',
        label: 'Run',
        keybindings: [
          monaco.KeyMod.CtrlCmd | monaco.KeyCode.Enter
        ],
        precondition: 'hasConnection && !hasQuery',
        contextMenuGroupId: 'yellowbrick',
        contextMenuOrder: order++,
        run: (editor) => {
          this.$emit('execute', 'run')
        }
      })
      editor.addAction({
        id: 'yb.run.selected',
        label: 'Run Selected',
        keybindings: [
          monaco.KeyMod.CtrlCmd | monaco.KeyMod.Shift | monaco.KeyCode.Enter
        ],
        precondition: 'hasConnection && hasSelection',
        contextMenuGroupId: 'yellowbrick',
        contextMenuOrder: order++,
        run: (editor) => {
          this.$emit('execute', 'run.selected')
        }
      })
      editor.addAction({
        id: 'yb.run.cancel',
        label: 'Cancel Query',
        keybindings: [
          monaco.KeyMod.WinCtrl | monaco.KeyCode.KEY_C
        ],
        precondition: 'hasQuery',
        contextMenuGroupId: 'yellowbrick',
        contextMenuOrder: order++,
        run: (editor) => {
          this.$emit('execute', 'run.cancel')
        }
      })
      editor.addAction({
        id: 'yb.file.new',
        label: 'New',
        keybindings: [
          monaco.KeyMod.WinCtrl | monaco.KeyCode.KEY_N
        ],
        run: (editor) => {
          this.$emit('execute', 'file.new')
        }
      })
      editor.addAction({
        id: 'yb.file.open',
        label: 'Open',
        keybindings: [
          monaco.KeyMod.WinCtrl | monaco.KeyCode.KEY_O
        ],
        run: (editor) => {
          this.$emit('execute', 'file.open')
        }
      })
      editor.addAction({
        id: 'yb.file.import',
        label: 'Import',
        keybindings: [
          monaco.KeyMod.WinCtrl | monaco.KeyCode.KEY_I
        ],
        run: (editor) => {
          this.$emit('execute', 'file.import')
        }
      })
      editor.addAction({
        id: 'yb.file.save',
        label: 'Save',
        keybindings: [
          monaco.KeyMod.WinCtrl | monaco.KeyCode.KEY_S
        ],
        run: (editor) => {
          this.$emit('execute', 'file.save')
        }
      })
      editor.addAction({
        id: 'yb.file.close',
        label: 'Close',
        keybindings: [
          monaco.KeyMod.WinCtrl | monaco.KeyCode.KEY_W
        ],
        run: (editor) => {
          this.$emit('execute', 'file.close')
        }
      })
      editor.addAction({
        id: 'yb.file.closeall',
        label: 'Close All',
        keybindings: [
        ],
        run: (editor) => {
          this.$emit('execute', 'file.closeall')
        }
      })
      editor.addAction({
        id: 'yb.file.closeothers',
        label: 'Close Others',
        keybindings: [
        ],
        run: (editor) => {
          this.$emit('execute', 'file.closeothers')
        }
      })
      editor.addAction({
        id: 'yb.file.clear',
        label: 'Clear',
        keybindings: [
        ],
        run: (editor) => {
          this.execute('yb.file.clear')
        }
      })
      editor.addAction({
        id: 'yb.action.reformat',
        label: 'Reformat',
        keybindings: [
          monaco.KeyMod.WinCtrl | monaco.KeyCode.KEY_R
        ],
        run: (editor) => {
          this.execute('action.reformat')
        }
      })
      editor.addAction({
        id: 'yb.action.fullscreen',
        label: 'Fullscreen',
        keybindings: [
          monaco.KeyMod.WinCtrl | monaco.KeyMod.Alt | monaco.KeyCode.KEY_F,
          monaco.KeyCode.F11
        ],
        run: (editor) => {
          this.$emit('execute', 'yb.action.fullscreen')
        }
      })
      editor.addAction({
        id: 'yb.action.zoom',
        label: 'Zoom Editor (Zen Mode)',
        keybindings: [
          monaco.KeyMod.WinCtrl | monaco.KeyMod.Alt | monaco.KeyCode.KEY_Z,
          monaco.KeyCode.F10
        ],
        run: (editor) => {
          this.$emit('execute', 'yb.action.zoom')
        }
      })
      editor.addAction({
        id: 'yb.action.search',
        label: 'Search Database Objects',
        keybindings: [
          monaco.KeyMod.CtrlCmd | monaco.KeyCode.KEY_G
        ],
        run: (editor) => {
          this.$emit('execute', 'yb.action.search')
        }
      })
      editor.addAction({
        id: 'yb.action.history',
        label: 'Show Query History',
        keybindings: [
          monaco.KeyMod.CtrlCmd | monaco.KeyCode.KEY_H
        ],
        run: (editor) => {
          this.$emit('execute', 'yb.action.history')
        }
      })
      editor.addCommand(
        monaco.KeyCode.Tab,
        () => {
          // Tab completion will only work if cursor is not at beginning of line (ie. indent behavior)

          const pos = editor.getPosition()
          const model = editor.getModel()
          const line = pos.lineNumber
          const col = pos.column
          const textUntilPosition = model.getValueInRange({
            startLineNumber: line,
            startColumn: 1,
            endLineNumber: line,
            endColumn: col
          }).trim()
          const textAfterPosition = model.getValueInRange({
            startLineNumber: line,
            startColumn: col,
            endLineNumber: line,
            endColumn: col + 10000
          }).trim()
          false && console.log('textUntilPosition', textUntilPosition)
          false && console.log('textAfterPosition', textAfterPosition)
          if (!!textAfterPosition && !textUntilPosition) {
            false && console.log('trigger indent')
            editor.trigger('', 'editor.action.indentLines', '')
          } else {
            false && console.log('trigger suggest')
            editor.trigger('', 'editor.action.triggerSuggest', '')
          }
        },
        'editorTextFocus && !editorHasSelection && !editorHasMultipleSelections && !editorTabMovesFocus && !hasQuickSuggest')

      // Set the yb editor model on the editor instance model, so we can gain access during resource provider callback.
      this.editorInstance.getModel().__yb_editor = this.editor
      this.editorInstance.getModel().__yb_editorComponent = this

      // And we're ready.
      this.$emit('ready')
    },

    editorSelected(selection) {
      this.editor.hasSelection = selection
      this.$emit('selected', selection)
    },

    focus() {
      if (!this.$refs.textEditor) {
        window.setTimeout(() => {
          if (this.$refs.textEditor) {
            this.$refs.textEditor.focus()
          }
        }, 2000)
      } else {
        this.$refs.textEditor.focus()
      }
    },

    selected() {
      return this.$refs.textEditor.selected()
    },

    execute(id, payload) {
      if (!this.editorInstance) {
        console.error(`Cannot dispatch command ${id} before editor instance arrives`)
        return
      }
      if (id === 'editor.action.clipboardCutAction' || id === 'cut') {
        this.editorInstance.focus()
        if (document.queryCommandSupported('cut')) {
          document.execCommand('cut')
        } else {
          this.editorInstance.trigger('yb', id, payload)
        }
      } else if (id === 'editor.action.clipboardCopyAction' || id === 'copy') {
        this.editorInstance.focus()
        if (document.queryCommandSupported('copy')) {
          document.execCommand('copy')
        } else {
          this.editorInstance.trigger('yb', id, payload)
        }
      } else if (id === 'editor.action.clipboardPasteAction' || id === 'paste') {
        if (!payload) {
          this.nativePaste()
        } else {
          this.editorInstance.trigger('yb', id, payload)
        }
      } else if (id === 'editor.action.selectAll') {
        this.editorInstance.focus()
        this.editorInstance._commandService.executeCommand('editor.action.selectAll')
      } else if (id === 'yb.edit.clear') {
        this.editorInstance.focus()
        this.editorInstance._commandService.executeCommand('editor.action.selectAll')
        this.editorInstance.trigger('yb', 'deleteAllLeft')
      } else if (id === 'action.reformat') {
        let indent = '   '
        const indentLevel = Number(this.$store.get('settings/editor@indentLevel') || 4)
        if (indentLevel > 0) {
          indent = ''
          for (let i = 0; i < indentLevel; ++i) {
            indent += ' '
          }
        }
        const text = sqlFormatter.format(this.editor.settings.sql, { indent })
        this.editorInstance.focus()
        this.editorInstance._commandService.executeCommand('editor.action.selectAll')
        this.editorInstance.trigger('yb', 'paste', { text })
      } else if (id.match(/^command\./)) {
        this.editorInstance.focus()
        this.editorInstance._commandService.executeCommand(id.substring('command.'.length))
      } else {
        this.editorInstance.focus()
        this.editorInstance.trigger('yb', id, payload)
      }
    },

    async nativePaste() {
      try {
        document.documentElement.focus()
        const text = await navigator.clipboard.readText()
        this.editorInstance.focus()
        this.editorInstance.trigger('yb', 'paste', { text })
      } catch (e) {
        if (document.queryCommandSupported('paste')) {
          document.execCommand('paste')
        } else {
          console.error(e)
        }
      }
    },

    syntaxCheckImpl() {
      const editor = this.editorInstance
      const position = editor.getPosition()
      provideCompletionItemsFromModel(editor.getModel(), position, this.monaco, true)
    }
  }
}

</script>
