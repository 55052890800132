<template>
  <div v-resize class="h-full w-full overflow-auto border rounded-lg yb-border-content" @resize="resize">
    <er-diagram v-if="!loading" :key="iteration" class="h-auto w-full" :entities="entities" :relations="relations" />
    <yb-loading v-else class="yb-center" />
  </div>
</template>

<script>
import ErDiagram from 'gethue/components/er-diagram/index.vue'
import 'gethue/components/er-diagram/er-diagram.scss'
import debounce from 'debounce'

import { databaseObjectService } from '@/services'
import { functions } from '@/util'

export default {
  components: {
    ErDiagram
  },
  props: {
    database: {
      type: String,
      required: true
    },
    schemaId: {
      type: Number,
      required: true
    },
    tableId: {
      type: Number,
      required: false
    }
  },
  data() {
    return {
      loading: false,
      entities: [],
      relations: [],
      iteration: 0
    }
  },
  beforeMount() {
    this.resize = debounce(this.resizeImpl.bind(this), 1000)
  },
  async mounted() {
    this.loading = true
    try {
      // Get the raw data.
      const { tables, relationships, columns } = await databaseObjectService.getRelationships(this.url, this.database, this.schemaId)

      // NB: turn this into the form @gethue/components/er-diagram/test/data.json

      /*
      Table:
      {
        "id": "hue6.desktop_document2",
        "type": "TABLE",
        "database": "hue6",
        "name": "desktop_document2",
        "columns": [
          {
            "id": "hue6.desktop_document2.id",
            "type": "COLUMN",
            "tableId": "hue6.desktop_document2",
            "name": "id"
          },
        ]
      }
      */

      // Map entities w/columns.
      const entities = tables.map((t) => {
        return {
          id: t.table_id,
          type: 'TABLE',
          database: this.database,
          name: t.name,
          columns: []
        }
      })
      const tableMap = functions.indexBy(entities, 'id')
      columns.forEach((col) => {
        const table = tableMap[col.table_id]
        if (table) {
          table.columns.push({
            id: `${col.table_id}:${col.column_id}`,
            type: 'COLUMN',
            tableId: col.table_id,
            name: col.name
          })
        }
      })

      // Map relations.
      /*
      Relationship:
      {
        {
          "desc": "Foreign Key",
          "left": {
            "id": "hue6.desktop_document2.parent_directory_id",
            "type": "COLUMN",
            "tableId": "hue6.desktop_document2",
            "name": "parent_directory_id"
          },
          "right": {
            "id": "hue6.desktop_document2.id",
            "type": "COLUMN",
            "tableId": "hue6.desktop_document2",
            "name": "id"
          }
        },
      }
      */
      // NB: FOREIGN KEY (customer_id) REFERENCES kurt.customers(customer_id)
      const constraintRE = /.*?\((.*?)\).*?\((.*?)\)/
      const relations = relationships.map((relationship) => {
        const [, source_column_name, target_column_name] = constraintRE.exec(relationship.constraint)

        return {
          desc: relationship.constraint,
          left: {
            id: `${relationship.table_id}:${relationship.column_id}`,
            type: 'COLUMN',
            tableId: relationship.table_id,
            name: source_column_name
          },
          right: {
            id: `${relationship.target_table_id}:${relationship.target_column_id}`,
            type: 'COLUMN',
            tableId: relationship.target_table_id,
            name: target_column_name
          }
        }
      })

      // Set the data.
      this.relations = relations
        .filter(r => !this.tableId || r.left.tableId === this.tableId || r.right.tableId === this.tableId)
      const tablesAllowed = new Set()
      if (this.tableId) {
        tablesAllowed.add(this.tableId)
        this.relations.forEach((relation) => {
          tablesAllowed.add(relation.left.tableId)
          tablesAllowed.add(relation.right.tableId)
        })
      }
      this.entities = entities
        .filter(e => !this.tableId || tablesAllowed.has(e.id))
    } finally {
      this.loading = false
    }
  },
  methods: {
    resizeImpl() {
      this.iteration++
    }
  }
}
</script>

<style lang="postcss">
.er-diagram {
  @apply border-none;
}

.dark .er-diagram {
  background: linear-gradient(90deg, #3a3a3a 9px, transparent 1%) center, linear-gradient(#3a3a3a 9px, transparent 1%) center, #bbb;
  background-size: 10px 10px;
  color: #57c0cb;
}

.er-diagram .erd-relations .relation-path {
  stroke: rgb(254,207,65);

}

.er-diagram .table-entity .columns-container .column-entity {
  @apply z-50;
  @apply p-1 m-0.5 rounded-sm text-yb-gray-medium;
  @apply text-xs text-yb-gray-medium;
  @apply dark:bg-yb-gray-mud;
  @apply dark:text-yb-gray-faintest;
  @apply dark:border-yb-gray-medium;

}
.table-name {
  @apply dark:text-yb-blue;
  @apply font-normal;
}

.db-name {
  @apply dark:text-yb-gray-faintest font-normal;
}
.table-entity {
  @apply bg-yb-gray-lightest;
  @apply border-yb-gray-lighter;
  @apply dark:bg-yb-gray-darker;
  @apply dark:border-yb-gray-darker;
}
.erd-container {
  @apply flex flex-wrap flex-row;
}
.dark .table-entity .columns-container .grouped-columns {

  box-shadow:-2px -2px 0 #212121,
-3px -3px 0 #4d5357,
-5px -5px 0 #212121,
-6px -6px 0 #4d5357;
  @apply bg-yb-gray-mud border-yb-gray-medium;

}
.columns-container {
  @apply dark:text-yb-blue font-normal;
}

</style>
