<template>
  <div class="yb-view dark:bg-yb-gray-alt-dark" data-test-id="query-schema-browser">
    <div class="yb-view-header relative mr-4 my-2">
      <yb-search-input
        ref="search"
        v-model="searchString"
        placeholder="Search Database Objects"
        data-test-id="query-schema-browser-search"
        wrapper-classes="w-full"
        input-classes="ml-2 w-full px-1 py-1.5 yb-input text-sm"
        @clear="focus"
      />
    </div>

    <splitpanes v-show="!loading" class="yb-view-content yb-bg-content dark:bg-yb-gray-alt-dark" horizontal @resize="editor.settings.splitT = $event.map(item => item.size)">
      <pane :size="editor.settings.splitT[0]" :min-size="10" class="relative">
        <div v-show="refreshing" class="h-6 w-6 absolute bottom-0 right-0">
          <yb-processing v-tooltip="'Refreshing...'" class="w-full h-full" />
        </div>
        <div class="w-auto h-full select-none scroller" @contextmenu.prevent="menu">
          <template v-if="searchString">
            <recycle-scroller
              v-slot="{ item, index }"
              class="h-full w-auto overflow-auto select-none scroller"
              :items="searchItems"
              :item-size="32"
              key-field="key"
            >
              <yb-object-tree-item
                :id="item.id"
                :data-test-id="'search:' + index"
                :type="item.__type"
                :name="item.name"
                :label="item.label"
                :item="item"
                :item-key="item.key"
                :selected-key="selectedKey"
                :database-id="item.database_id"
                :database-name="item.database_name"
                :schema-name="item.schema_name"
                :schema-id="item.schema_id"
                :detail="true"
                leaf
                @click="click"
              />
            </recycle-scroller>
          </template>

          <template v-else>
            <recycle-scroller
              v-slot="{ item }"
              class="h-full w-auto overflow-auto select-none scroller"
              :items="items"
              :item-size="32"
              key-field="key"
            >
              <yb-object-tree-item
                :id="item.id"
                :key="item.key"
                :type="item.type"
                :item-key="item.key"
                :item="item.item"
                :data-test-id="item.dataTestId"
                :name="item.name"
                :initial-open="item.initialOpen"
                :always-open="item.alwaysOpen"
                :database-id="item.databaseId"
                :database-name="item.databaseName"
                :schema-id="item.schemaId"
                :expanded="item.expanded"
                :selected-key="selectedKey"
                :level="item.level"
                :leaf="item.leaf"
                @click="click"
                @open="open(item.key, $event)"
                @query="query"
                @contextmenu.stop.prevent="menuItem($event, item)"
              />
            </recycle-scroller>
          </template>
        </div>
      </pane>
      <pane :size="editor.settings.splitT[1]" :min-size="0">
        <yb-detail-database
          v-if="selected && selected.type === 'database'"
          :id="selected.id"
          ref="detailDatabase"
          :instance-id="selectedInstanceId"
          :name="selected.name"
          :data-test-id="'detail:database' + selected.name"
          @menu="menuDatabase($event, selected.item)"
        />
        <yb-detail-schema
          v-if="selected && selected.type === 'schema'"
          :id="selected.id"
          ref="detailSchema"
          :data-test-id="'detail:schema' + selected.name"
          :instance-id="selectedInstanceId"
          :database-id="selected.databaseId"
          :database-name="selected.databaseName"
          :name="selected.name"
          @menu="menuSchema($event, selected.item)"
        />
        <yb-detail-table
          v-if="selected && selected.type === 'table'"
          :data-test-id="'detail:table' + selected.name"
          :instance-id="selectedInstanceId"
          :item="selected.item"
          @menu="menuTable($event, selected.item)"
        />
        <yb-menu-database
          ref="menuDatabase"
        />
        <yb-menu-schema
          ref="menuSchema"
        />
        <yb-menu-table
          ref="menuTable"
          @query="query"
          @execute="execute"
          @explore="explore"
        />
      </pane>
    </splitpanes>
    <div v-show="loading" class="yb-view-content yb-center">
      <yb-loading />
    </div>

    <vue-context ref="menu" class="yb-v-context">
      <div class="yb-menu-heading">
        <yb-icon class="yb-button-icon" icon="action" />
        Actions
      </div>
      <li>
        <a class="indent" @click.prevent="collapse">
          Collapse All
        </a>
      </li>
      <li>
        <a class="indent" @click.prevent="refresh">
          Refresh
        </a>
      </li>
    </vue-context>
  </div>
</template>

<script>
import { Splitpanes, Pane } from 'splitpanes'
import YbEditorMixin from './EditorMixin'
import YbObjectTreeItem from './ObjectTreeItem.vue'
import YbDetailDatabase from './DetailDatabase.vue'
import YbDetailSchema from './DetailSchema.vue'
import YbDetailTable from './DetailTable.vue'
import YbMenuDatabase from './MenuDatabase.vue'
import YbMenuSchema from './MenuSchema.vue'
import YbMenuTable from './MenuTable.vue'
import VueContext from '@/lib/vue-context/js'
import * as functions from '@/util/functions'
import Database from '@/models/Database'
import Schema from '@/models/Schema'
import Table from '@/models/Table'
import View from '@/models/View'
import Sequence from '@/models/Sequence'

export default {
  components: {
    Splitpanes,
    Pane,
    VueContext,
    YbObjectTreeItem,
    YbDetailDatabase,
    YbDetailSchema,
    YbDetailTable,
    YbMenuDatabase,
    YbMenuSchema,
    YbMenuTable
  },
  mixins: [YbEditorMixin],
  props: {
    selectedInstanceId: String,
    selectedInstanceName: String,
    refreshing: Boolean
  },
  data() {
    return {
      selected: null,
      selectedKey: null,
      searchString: ''
    }
  },
  computed: {
    databases() {
      return Database.query().where('instance_id', this.selectedInstanceId).get().sort(functions.sortByName)
    },
    searchItems() {
      const expr = new RegExp(this.searchString, 'i')
      const result = []
      const { selectedInstanceId } = this;
      [
        Database,
        Schema,
        Table,
        View,
        Sequence
      ].forEach((type) => {
        type.query().where('instance_id', selectedInstanceId).get().forEach((object) => {
          if (String(object?.name).match(expr)) {
            const key = Array.isArray(object.$id) ? object.$id.join(':') : object.$id
            result.push(Object.freeze({
              ...object,
              type: object.__type,
              key,

              // Re-introduce computed properties.
              id: object.id,
              database_id: object.database_id,
              database_name: object.database_name,
              schema_id: object.schema_id,
              schema_name: object.schema_name
            }))
          }
        })
      })
      return Object.freeze(result)
    },
    items() {
      const result = []
      const { editor } = this
      const expanded = new Set()
      Object.entries(this.editor?.settings?.expanded || {}).forEach(([key, value]) => {
        if (value) {
          expanded.add(key)
        }
      })

      result.push({
        type: 'instance',
        key: 'instance:' + this.selectedInstanceId,
        dataTestId: 'instance:' + this.selectedInstanceName,
        name: this.selectedInstanceName,
        id: this.selectedInstanceId,
        initialOpen: true,
        alwaysOpen: true,
        expanded: !!expanded['instance:' + this.selectedInstanceId],
        level: 0
      })

      // For each database.
      this.databases.forEach((d) => {
        const key = 'database:' + d.database_id
        result.push({
          type: 'database',
          key,
          dataTestId: 'database:' + d.name,
          name: d.name,
          id: d.database_id,
          item: d,
          initialOpen: d.name == editor.settings.database,
          expanded: expanded.has(key),
          level: 1
        })
        if (expanded.has(key)) {
          d.schemas.forEach((s) => {
            const key = 'database:' + d.database_id + ':schema:' + s.schema_id
            result.push({
              type: 'schema',
              key,
              dataTestId: 'database:' + d.name + ':schema:' + s.name,
              name: s.name,
              id: s.schema_id,
              item: s,
              databaseId: d.database_id,
              databaseName: d.name,
              expanded: expanded.has(key),
              level: 2
            })
            if (expanded.has(key)) {
              if (s.tables.length > 0) {
                const key = 'database:' + d.database_id + ':schema:' + s.schema_id + ':tables'
                result.push({
                  type: 'folder',
                  key,
                  dataTestId: 'database:' + d.name + ':schema:' + s.name + ':tables',
                  name: `${s.tables.length} Tables`,
                  id: -1,
                  expanded: expanded.has(key),
                  level: 3
                })
                if (expanded.has(key)) {
                  s.tables.forEach((t) => {
                    const key = 'database:' + d.database_id + ':schema:' + s.schema_id + ':table:' + t.table_id
                    result.push({
                      type: 'table',
                      key,
                      dataTestId: 'database:' + d.name + ':schema:' + s.name + ':table:' + t.name,
                      name: t.name,
                      id: t.table_id,
                      item: t,
                      databaseId: d.database_id,
                      databaseName: d.name,
                      schemaId: s.schema_id,
                      schemaName: s.name,
                      expanded: expanded.has(key),
                      leaf: true,
                      level: 4
                    })
                  })
                }
              }

              if (s.views.length > 0) {
                const key = 'database:' + d.database_id + ':schema:' + s.schema_id + ':views'
                result.push({
                  type: 'folder',
                  key,
                  dataTestId: 'database:' + d.name + ':schema:' + s.name + ':views',
                  name: `${s.views.length} Views`,
                  id: -1,
                  expanded: expanded.has(key),
                  level: 3
                })
                if (expanded.has(key)) {
                  s.views.forEach((t) => {
                    const key = 'database:' + d.database_id + ':schema:' + s.schema_id + ':view:' + t.view_id
                    result.push({
                      type: 'view',
                      key,
                      dataTestId: 'database:' + d.name + ':schema:' + s.name + ':view:' + t.name,
                      name: t.name,
                      id: t.view_id,
                      item: t,
                      databaseId: d.database_id,
                      databaseName: d.name,
                      schemaId: s.schema_id,
                      schemaName: s.name,
                      expanded: expanded.has(key),
                      leaf: true,
                      level: 4
                    })
                  })
                }
              }

              if (s.sequences.length > 0) {
                const key = 'database:' + d.database_id + ':schema:' + s.schema_id + ':sequences'
                result.push({
                  type: 'folder',
                  key,
                  dataTestId: 'database:' + d.name + ':schema:' + s.name + ':sequences',
                  name: `${s.sequences.length} Sequences`,
                  id: -1,
                  expanded: expanded.has(key),
                  level: 3
                })
                if (expanded.has(key)) {
                  s.sequences.forEach((t) => {
                    const key = 'database:' + d.database_id + ':schema:' + s.schema_id + ':sequence:' + t.sequence_id
                    result.push({
                      type: 'sequence',
                      key,
                      dataTestId: 'database:' + d.name + ':schema:' + s.name + ':sequence:' + t.name,
                      name: t.name,
                      id: t.sequence_id,
                      item: t,
                      databaseId: d.database_id,
                      databaseName: d.name,
                      schemaId: s.schema_id,
                      schemaName: s.name,
                      expanded: expanded.has(key),
                      leaf: true,
                      level: 4
                    })
                  })
                }
              }
            }
          })
        }
      })

      return Object.freeze(result.map(Object.freeze))
    }
  },
  methods: {
    click(selected) {
      if (!!selected && selected.type !== 'folder') {
        this.selected = selected
        this.selectedKey = selected.itemKey
        selected && this.$emit('select', selected)
      }
    },
    open(itemKey, _) {
      if (!this.editor.settings.expanded) {
        this.editor.settings.expanded = {}
      }
      if (!!_ && !this.leaf) {
        this.editor.settings.expanded[itemKey] = _
      } else {
        delete this.editor.settings.expanded[itemKey]
      }
    },
    menuDatabase($event, selected) {
      this.closeMenus()
      this.$refs.menuDatabase.menu(selected.item || selected, $event)
    },
    menuSchema($event, selected) {
      this.closeMenus()
      this.$refs.menuSchema.menu(selected.item || selected, $event)
    },
    menuTable($event, selected) {
      this.closeMenus()
      this.$refs.menuTable.menu(selected.item || selected, $event)
    },
    menuItem($event, selected) {
      if (selected.type === 'database') {
        this.menuDatabase($event, selected.item || selected)
      } else if (selected.type === 'schema') {
        this.menuSchema($event, selected.item || selected)
      } else if (selected.type === 'table') {
        this.menuTable($event, selected.item || selected)
      }
    },
    menu(event) {
      this.closeMenus()
      window.setTimeout(() => this.$refs.menu.open(event), 100)
    },
    closeMenus() {
      this.$refs.menuDatabase && this.$refs.menuDatabase.closeMenu()
      this.$refs.menuSchema && this.$refs.menuSchema.closeMenu()
      this.$refs.menuTable && this.$refs.menuTable.closeMenu()
      this.$refs.menu && this.$refs.menu.close()
    },
    collapse() {
      Object.keys(this.editor.settings.expanded).forEach((item) => {
        delete this.editor.settings.expanded[item]
      })
      this.selected = null
      this.selectedKey = null
    },
    refresh(event) {
      this.$emit('refresh', event)
    },
    query(event) {
      this.$emit('query', event)
    },
    focus() {
      this.$emit('focus')
    },
    search() {
      this.$refs.search.focus()
    },
    execute(event) {
      this.$emit('execute', event)
    },
    explore(event) {
      this.$emit('explore', event)
    }
  }
}
</script>
