<template>
  <div class="yb-view select-text">
    <div class="yb-view-header">
      <h5 class="w-full yb-bg-pane p-2">
        <div class="flex items-center content-center">
          <yb-icon class="yb-button-icon" icon="database" />
          <span class="font-semibold">{{ name }}</span>
          <span v-yb-clipboard.text="id" v-tooltip="'Copy database id to clipboard'" class="circle cursor-pointer text-xs ml-1.5">ID</span>
        </div>
        <div class="flex flex-row justify-between items-center">
          <p class="text-sm">
            <span v-show="!detailLoading">
              <span v-if="detailError">(Error retrieving details)</span>
              <span v-else-if="!rows && !size" />
              <span v-else>
                <span v-if="rows">{{ humanizeNumber(rows, 0) }} rows</span>
                <span v-if="rows && size">, {{ bytes(size) }} (compressed)</span>
                <span v-if="rows && uncompressed_bytes">, {{ bytes(uncompressed_bytes) }} (uncompressed)</span>
              </span>
            </span>
          </p>
          <div class="flex space-x-2 self-end uppercase text-xs">
            <a href="#" @click="menu">
              <yb-dots />
            </a>
          </div>
        </div>
      </h5>
    </div>
    <div class="yb-view-content p-2">
      <div v-if="!detailLoading && model && empty">
        There are no objects contained in this database.
      </div>
      <div v-if="!detailLoading && model && counts">
        <p v-for="(objectType, index) in objectTypes" :key="index">
          <span v-if="counts[objectType.type] > 1">
            {{ counts[objectType.type] }} {{ objectType.label }}s
          </span>
          <span v-if="counts[objectType.type] == 1">
            One {{ objectType.label }}
          </span>
          <span v-if="counts[objectType.type] == 0 && false">
            No {{ objectType.label }}s
          </span>
        </p>
      </div>
      <div v-else-if="detailLoading && !detailError">
        <yb-loading class="self-center text-center m-5" />
      </div>
    </div>
  </div>
</template>

<script>
import YbEditorMixin from './EditorMixin'
import { jolokiaService, mbeans } from '@/services'
import VueContext from '@/lib/vue-context/js'
import Database from '@/models/Database'
import { humanizeNumber, bytes } from '@/filters'

export default {
  components: {
    VueContext
  },
  mixins: [YbEditorMixin],
  props: {
    instanceId: String,
    name: String,
    id: Number
  },
  data() {
    return {
      detailLoading: true,
      detailError: false,
      rows: 0,
      size: 0,
      uncompressed_bytes: 0
    }
  },
  computed: {
    model() {
      return !this.detailError && Database.query().whereId([this.instanceId, this.id]).first()
    },
    counts() {
      return this.model.counts
    },
    empty() {
      return Object.values(this.counts).reduce((v, sum) => v + sum, 0) === 0
    }
  },
  watch: {
    name() {
      this.update()
    }
  },
  mounted() {
    this.update()
  },
  methods: {
    humanizeNumber,
    bytes,
    async update() {
      try {
        this.detailError = false
        this.detailLoading = true

        const storageSql = `select rows_columnstore, rows_rowstore, table_count, compressed_bytes, uncompressed_bytes from sys.database where database_id = ${this.id}`
        const result = await jolokiaService.execute(mbeans.datasources, 'queryEx', [this.name, storageSql, null, 0])

        if (result?.rows && result.rows.length > 0) {
          this.rows = result.rows[0].rows_columnstore + result.rows[0].rows_rowstore
          this.size = result.rows[0].compressed_bytes
          this.uncompressed_bytes = result.rows[0].uncompressed_bytes
        }
      } catch (e) {
        this.detailError = true
        if (!this.editor.showError) {
          this.editor.error = e
          this.editor.showError = true
        }
      } finally {
        this.detailLoading = false
      }
    },

    menu($event) {
      this.$emit('menu', $event)
    }
  }
}
</script>
