<template>
  <yb-drop-down-button label="File" icon="file" v-bind="$attrs" max-height="h-auto" action-link>
    <yb-drop-down-key command="yb.file.new" label="New" @click="click" />
    <hr>
    <yb-drop-down-key command="yb.file.open" label="Open..." @click="click" />
    <yb-drop-down-key command="yb.file.import" label="Import" :disabled="editor.loading" @click="click" />
    <yb-drop-down-key command="yb.file.save" label="Save" @click="click" />
    <hr>
    <yb-drop-down-key command="yb.run" label="Run" :disabled="editor.loading" @click="click" />
    <yb-drop-down-key command="yb.run.selected" label="Run Selected" :disabled="editor.loading || !editor.hasSelection" @click="click" />
    <yb-drop-down-key command="yb.run.cancel" label="Cancel" :disabled="!editor.loading" @click="click" />
    <hr>
    <yb-drop-down-key command="yb.file.closeall" label="Close All" @click="click" />
    <yb-drop-down-key command="yb.file.closeothers" label="Close Others" @click="click" />
    <hr>
    <yb-drop-down-key command="yb.file.close" label="Close" @click="click" />
  </yb-drop-down-button>
</template>

<script>

import YbDropDownKey from './DropDownKey.vue'
import YbEditorMixin from './EditorMixin'

export default {
  components: {
    YbDropDownKey
  },
  mixins: [YbEditorMixin],
  emits: ['click'],
  methods: {
    click(id) {
      this.$emit('click', id)
    },

    toggleSchemaBrowser() {
      this.editor.settings.schemaBrowser = !this.editor.settings.schemaBrowser
    }
  }
}
</script>
