<template>
  <div
    class="flex flex-col p-0.5 cursor-pointer"
    :data-test-id="derivedDataTestId"
    :style="{marginLeft: (level * 30) + 'px'}"
  >
    <div class="flex flex-row flex-shrink-0 items-center hover:bg-yb-rollover dark:hover:bg-yb-gray-lighter dark:hover:text-yb-gray-dark" @click.stop.prevent="click" @contextmenu.native="contextmenu">
      <div class="inline-block" @click.stop.prevent="clickToggle">
        <yb-icon v-if="!leaf && !alwaysOpen" :fill="true" class="yb-button-icon-sm flex-shrink-0 stroke-0" :icon="open ? 'nav-arrow-down' : 'nav-arrow-right'" />
      </div>
      <yb-icon v-if="type" class="icon-type m-1 flex-none flex-shrink-0" :icon="type" @contextmenu.native="contextmenu" />
      <div v-if="label || name" class="flex-none truncate text-sm" :class="{'font-semibold': isSelected}" @contextmenu.native="contextmenu">
        {{ label || name }}
      </div>
      <slot v-else name="label" />
    </div>
    <div v-if="populated && (open || alwaysOpen)" class="ml-6">
      <slot :id="id" name="children" :item_name="name" />
    </div>
  </div>
</template>

<script type="ts">
export default {
  props: {
    type: String,
    name: String,
    label: String,
    item: Object,
    itemKey: String,
    id: [Number, String],
    databaseId: Number,
    databaseName: String,
    dataTestId: String,
    schemaName: String,
    schemaId: [Number, String],
    expanded: Boolean,
    initialOpen: Boolean,
    alwaysOpen: Boolean,
    selectedKey: String,
    leaf: Boolean,
    level: Number
  },
  emits: ['click', 'contextmenu', 'open'],
  data() {
    const open = !!this.expanded || !!this.initialOpen || !!this.alwaysOpen
    if (open) {
      this.$nextTick(() => {
        if (!this.isSelected) {
          this.$emit('click', this)
        }
      })
    }
    return {
      open
    }
  },
  computed: {
    isSelected() {
      return this.selectedKey === this.itemKey
    },
    populated() {
      return this.initialOpen || this.item?.populated
    },
    derivedDataTestId() {
      const { dataTestId } = this
      if (dataTestId) {
        return dataTestId
      } else {
        const { type, databaseName, name } = this
        return 'query-schema-browser-' + (type ? type + '-' : '') + (!String(type).match(/database|instance/) ? (databaseName + '.') : '') + name
      }
    }
  },
  watch: {
    open(_) {
      this.$emit('open', _)
    },
    initialOpen(n, o) {
      if (!!n && n !== o) {
        this.$nextTick(() => {
          if (!this.isSelected) {
            this.click()
          }
        })
      }
    },
    expanded(_) {
      if (!_) {
        this.open = false
      }
    }
  },
  mounted() {
    this.open = !!this.open || !!this.initialOpen || !!this.alwaysOpen
  },
  methods: {
    click() {
      this.$emit('click', this)
      if (this.isSelected) {
        this.open = !this.open
      } else if (!this.open) {
        this.open = true
      }
    },
    clickToggle() {
      this.open = !this.open
      if (this.open) {
        this.$emit('click', this)
      }
    },
    contextmenu($event) {
      $event.preventDefault()
      $event.stopPropagation()
      this.$emit('contextmenu', $event, this)
    }
  }
}
</script>

<style scoped>
.yb-icon.icon-type {
  width: 18px;
  height: 18px;
}
</style>
