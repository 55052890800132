<template>
  <yb-query-view />
</template>

<script lang="ts">
import YbQueryView from '@/app/query/QueryView.vue'

export default {
  name: 'Query',
  components: {
    YbQueryView
  },
  setup() {
    definePageMeta({
      name: "yb-query"
    })
  }
}
</script>
