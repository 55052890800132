import * as editorHelper from './editorHelper'

export default {
  inject: ['editor'],
  props: {
    showCommand: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      objectTypes: [
        { type: 'schema', label: 'Schema' },
        { type: 'table', label: 'Table' },
        { type: 'view', label: 'View' },
        { type: 'sequence', label: 'Sequence' },
        { type: 'function', label: 'Function' },
        { type: 'procedure', label: 'Procedure' },
        { type: 'storage', label: 'External Storage Definition' },
        { type: 'location', label: 'External Location' },
        { type: 'format', label: 'External Format' }
      ]
    }
  },
  computed: {
    rowset() {
      return this.editor && this.editor.rowset || {}
    },
    error() {
      return this.editor && this.editor.error || ''
    },
    errorMessage() {
      return editorHelper.formatErrorMessage(this.error)
    },
    hasRowset() {
      const { rowset } = this
      return rowset && (Number(rowset.execId) >= 0) && rowset.rows && rowset.rows.length
    },
    loading() {
      return this.editor && this.editor.databaseModelLoading
    }
  }
}
