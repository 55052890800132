import { jolokiaService, mbeans } from '@/services'
import { functions } from '@/util'

const defaultDetails = {
  uncompressed_bytes: 0,
  compressed_bytes: 0,
  distribution: null,
  sort_key: null,
  cluster_keys: null,
  partition_keys: null,
  distribution_key: null,
  rowstore_row_count: 0,
  rowstore_bytes: 0,
  columnstore_row_count: 0,
  columnstore_bytes: 0
}

export default {
  // NB: "item" is either a prop or data, depends on use
  data() {
    return {
      detailLoading: true,
      detailError: false,
      details: functions.clone(defaultDetails),
      columns: []
    }
  },
  computed: {
    instanceId() {
      return this.editor.settings.instance
    },
    name() {
      return this.item && this.item.name
    },
    id() {
      return this.item && this.item.id
    },
    databaseName() {
      return this.item && this.item.database_name
    },
    databaseId() {
      return this.item && this.item.database_id
    },
    schemaName() {
      return this.item && this.item.schema_name
    },
    schemaId() {
      return this.item && this.item.schema_id
    },
    path() {
      return `${this.schemaName}.${this.name}`
    }
  },
  methods: {
    async update() {
      try {
        const tableSql = `select distribution, compressed_bytes, uncompressed_bytes, coalesce(rowstore_row_count, 0) as rowstore_row_count, coalesce(rowstore_bytes, 0) as rowstore_bytes from sys.table where table_id = ${this.id}`
        const storageSql = `select sum(rows_columnstore) as columnstore_row_count, sum(compressed_bytes) as columnstore_bytes, sum(uncompressed_bytes) as columnstore_uncompressed_bytes, count(*) as worker_count from sys.table_storage where table_id = ${this.id}`
        const columnSql = `select column_id, name, type, nullable from sys.column where table_id = ${this.id} order by column_id`
        const primaryKeySql = `select col.column_name from information_schema.table_constraints tab join information_schema.constraint_column_usage col on col.constraint_name = tab.constraint_name and col.table_name = tab.table_name and tab.table_schema = col.table_schema where tab.table_name = '${this.name}' and col.table_schema = '${this.schemaName}'`
        const keysSql = `select sort_key, distribution_key, cluster_keys, partition_keys from sys.vt_table_keys_f(${this.id})`

        this.detailLoading = true
        this.detailError = false
        const results = await Promise.all([
          jolokiaService.execute(mbeans.datasources, 'queryEx', [this.databaseName, tableSql, null, 0]),
          jolokiaService.execute(mbeans.datasources, 'queryEx', [this.databaseName, storageSql, null, 0]),
          jolokiaService.execute(mbeans.datasources, 'queryEx', [this.databaseName, columnSql, null, 0]),
          jolokiaService.execute(mbeans.datasources, 'queryEx', [this.databaseName, primaryKeySql, null, 0]),
          jolokiaService.execute(mbeans.datasources, 'queryEx', [this.databaseName, keysSql, null, 0])
        ])

        this.details = results[0].rows[0] || functions.clone(defaultDetails)
        if (results[4]?.rows?.length > 0) {
          Object.entries(results[4]?.rows[0]).forEach(([key, value]) => {
            this.details[key] = value
          })
        }
        this.details.columnstore_row_count = results[1].rows[0]?.columnstore_row_count
        this.details.columnstore_bytes = results[1].rows[0]?.columnstore_bytes
        this.details.columnstore_uncompressed_bytes = results[1].rows[0]?.columnstore_uncompressed_bytes
        if (this.details.distribution === 'replicated' && results[1].rows[0]?.worker_count > 0) {
          this.details.columnstore_row_count /= results[1].rows[0]?.worker_count
          this.details.columnstore_bytes /= results[1].rows[0]?.worker_count
          this.details.columnstore_uncompressed_bytes /= results[1].rows[0]?.worker_count
        }
        const { rows: columns } = results[2] || []
        this.columns = columns
        const primaryKey = results[3]?.rows[0]?.column_name
        if (primaryKey) {
          const primaryKeyCol = this.columns.find(col => col.name === primaryKey)
          if (primaryKeyCol) {
            primaryKeyCol.primaryKey = true
          }
        }
      } catch (e) {
        this.detailError = true
        if (!this.editor.showError) {
          this.editor.error = e
          this.editor.showError = true
        }
      } finally {
        this.detailLoading = false
      }
    }
  }
}
