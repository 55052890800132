<template>
  <div class="flex relative" :data-test-id="dataTestId">
    <div :class="buttonClass" @click="$emit('open')">
      <yb-icon v-if="icon" :class="iconClass" :icon="icon" />

      <span v-if="label">{{ label }}</span>
      <slot v-else name="label" />

      <div v-if="openClickArrow" class="inline-block ml-2 mr-1 h-5 bg-white dark:bg-yb-gray-light" style="width: 1px;" @click="arrowClick" />
      <div class="h-full flex items-center" @click="arrowClick">
        <div v-if="showArrow" class="w-4 h-4 leading-normal yb-center">
          <yb-icon class="yb-button-icon-sm w-2 stroke-0 fill-current" icon="nav-arrow-down" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import YbButtonMixin from './YbButtonMixin'

export default {
  mixins: [YbButtonMixin],
  props: {
    openClickArrow: Boolean,
    showArrow: {
      type: Boolean,
      default: true
    },
    label: String,
    iconClasses: String
  },
  emits: ['open', 'arrowClick'],
  methods: {
    arrowClick() {
      this.$emit('arrowClick')
    }
  }
}
</script>
