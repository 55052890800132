<template>
  <div class="h-8 w-8 hover:bg-yb-rollover dark:hover:text-yb-black select-none yb-center rounded-full items-center justify-center">
    <yb-icon class="yb-button-icon ml-1 fill-current stroke-current" icon="v-dots" />
  </div>
</template>

<script>
export default {
}
</script>
