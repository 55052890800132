<template>
  <div class="yb-view select-none pl-3 dark:bg-yb-gray-alt-dark" :class="{'pt-3': !currentTab || !currentTab.settings.zoomed}">
    <div ref="fullscreen" class="yb-view-content yb-view relative" :class="{'bg-white pt-1' : fullscreen}" @fullscreenchange="fullscreenChange">
      <div v-if="!currentTab || !currentTab.settings.zoomed" ref="tabs" class="yb-view-header w-full flex flex-row" style="padding-left: 1px">
        <div ref="tabsContainer" class="flex flex-row">
          <template v-for="(t, index) in tabs" :key="t.key">
            <yb-query-editor-tab
              v-model="t.label"
              :tab-id="t.key"
              :active="t.key == tab"
              :class="{'ml-4': index == 0}"
              @click="setTab(t.key)"
              @close="closeTab(t.key)"
              @change="save"
              @drag-move="dragMove"
              @drag-end="dragEnd"
            />
          </template>
        </div>
        <div class="h-8 flex-grow flex flex-row flex-nowrap items-center select-none space-x-2 ml-3">
          <div class="mt-1" data-test-id="query-new-tab-button" @click.prevent="addTab()">
            <yb-icon class="yb-button-icon text-gray-600 dark:text-gray-300 stroke-current cursor-pointer" icon="plus-circle" />
          </div>
          <div class="mt-1" data-test-id="query-manage-tabs-button" @click.prevent="openTabMenu">
            <yb-icon class="yb-button-icon text-gray-600 dark:text-gray-300 stroke-current cursor-pointer" icon="action" />
          </div>
          <input
            ref="fileInput"
            type="file"
            class="hidden"
            @change="fileOpen"
          >
          <input
            v-if="firefox"
            ref="focusMe"
            class="m-0 p-0 w-0 h-0"
            placeholder="I am to be focused leave me be"
          >
        </div>
      </div>
      <div class="yb-view-content pointer-events-none">
        <keep-alive>
          <yb-query-content
            v-if="currentTab"
            :id="currentTab.key"
            :key="currentTab.key"
            ref="editor"
            :settings="currentTab.settings"
            @change="change"
            @query="query"
            @execute="execute"
          />
        </keep-alive>
      </div>
    </div>
    <vue-context ref="tabmenu" class="yb-v-context">
      <li>
        <a @click.prevent="closeAll">Close All Tabs</a>
      </li>
      <li>
        <a @click.prevent="closeOthers">Close Other Tabs</a>
      </li>
    </vue-context>

    <transition name="dialog">
      <yb-modal
        v-if="showInstancesPromise"
        show
        title="Choose Instance for Query"
        type="form"
        dialog-classes="max-w-xl"
        content-classes="yb-view p-8"
        data-test-id="choose-instance"
        @close="showInstancesPromise[1](), showInstancesPromise = null"
      >
        <div class="yb-view-header">
          <h5 class="px-8">
            Please choose an instance to load this query:
          </h5>
        </div>
        <div class="yb-view-content-auto px-16">
          <div v-for="(instance, index) in userInstances" :key="index" class="my-2">
            <a class="yb-link flex flex-row flex-nowrap items-center font-normal hover:font-semibold" @click="showInstancesPromise[0](instance), showInstancesPromise = null">
              <div class="w-5 h-5 mr-2">
                <yb-icon :icon="getOpIcon(instance.activeStatus.description)" :class="getStatusColorClass(instance.activeStatus.description)" />
              </div>
              {{ instance.name }}
            </a>
          </div>
        </div>
      </yb-modal>
    </transition>
  </div>
</template>

<script>
import { markRaw } from 'vue'
import debounce from 'debounce'
import Dropzone from 'dropzone'
import { get, sync } from 'vuex-pathify'
import { v1 as uuidv1 } from 'uuid'
import FileSaver from 'file-saver'

import YbQueryContent from './Content.vue'
import YbQueryEditorTab from './QueryEditorTab.vue'
import { functions } from '@/util'
import Database from '@/models/Database'
import useInstancesLoader from '@/app/instance/InstancesLoader'

import VueContext from '@/lib/vue-context/js'
import { databaseObjectService } from '@/services'

export default {
  components: {
    VueContext,
    YbQueryEditorTab,
    YbQueryContent
  },
  setup () {
    return {
      ...useInstancesLoader()
    }
  },
  data() {
    return {
      tabs: functions.copyDeep(this.$store.get('query-editor/settings@tabs')) || [],
      tab: this.$store.get('query-editor/settings@tab'),
      fullscreen: false,
      showInstancesPromise: null
    }
  },
  computed: {
    selectedInstanceId: get('global/settings@instance.id', false),
    load: sync('query-editor/load', false),
    currentTab() {
      const { tabs, tab } = this
      return tabs.find(t => t.key === tab)
    },
    firefox() {
      return !!navigator.userAgent.match(/firefox/i)
    }
  },
  watch: {
    tab(tab) {
      this.updateCurrentEditor()
    },
    selectedInstanceId() {
      this.switchInstance()
    },
    load(_) {
      if (!!_ && !!this.active) {
        this.handleLoad(_)
      }
    }
  },
  beforeMount() {
    this.save = debounce(this.saveImpl.bind(this), 500)
  },
  mounted() {
    this.active = true

    // Setup drop files.
    this.dropzone = new Dropzone(this.$el, {
      url: '#',
      autoQueue: false,
      autoDiscover: false,
      maxFiles: 10,
      previewsContainer: false,
      clickable: false
    })
    this.dropzone.on('addedfile', (file) => {
      const fileReader = new FileReader()
      fileReader.onload = () => {
        this.addTab(file.name, fileReader.result)
      }
      fileReader.readAsText(file)
    })
    this.init()
  },
  activated() {
    this.active = true
    this.init()
  },
  deactivated() {
    this.active = false
  },
  beforeUnmount() {
    this.active = false
    if (this.dropzone) {
      this.dropzone.destroy()
    }
    this.dropzone = null
  },
  methods: {
    init() {
      // Grab instance and database from route.
      const { instance, database } = this.$route.query
      if (!!instance || !!database) {
        this.$router.replace(this.$route.path)
      }

      this.$nextTick(() => {
        // Do we have route params?  Look for a suitable tab.
        let newTab

        // Make a default tab if one doesn't exist.
        const { tab: lastTab, tabs } = this
        if (!tabs || !tabs.length) {
          newTab = this.addTab()
        } else if (tabs.length) {
          let tab

          // Do we have an instance and database?
          if (!!instance && !!database) {
            tab = tabs.find(tab => tab.settings?.instance === instance && tab.settings?.database === database)
            if (!tab) {
              newTab = this.addTab()
              tab = newTab
            }

          // Do we have an instance?
          } else if (instance) {
            tab = tabs.find(tab => tab.settings?.instance === instance)
            if (!tab) {
              newTab = this.addTab()
              tab = newTab
            }

          // Find the last tab we had open.
          } else {
            tab = tabs.find(tab => tab.key === lastTab)
          }

          // No tab?  Pick first one.
          if (!tab) {
            [tab] = tabs
          }

          // Activate the tab.
          this.setTab(tab.key)
        }

        // Did we make a new tab?
        if (newTab) {
          if (instance) {
            newTab.settings.instance = instance
          }
          if (database) {
            newTab.settings.database = database
          }
          newTab.settings.requestId = null
        }

        // Load the editor.
        this.updateCurrentEditor()

        // Do we have a load?
        !!this.load && this.handleLoad(this.load)
      })
    },

    switchInstance() {
      const { selectedInstanceId } = this
      this.updateCurrentEditor()
    },

    updateCurrentEditor() {
      const selectedTab = this.currentTab
      if (!selectedTab) { return }

      // Make the editor component focus.
      this.$nextTick(() => {
        const { editor } = this.$refs
        if (editor) {
          editor.focus()
          editor.activate()
          selectedTab.component = markRaw(editor)
        }
      })
    },

    makeTabLabel() {
      // Loop until we make a unique tab label

      for (let candidate = this.tabs.length + 1; true; ++candidate) {
        const result = `Query ${candidate}`
        if (!this.tabs.find(tab => tab.label === result)) {
          return result
        }
      }
    },

    addTab(_label = null, sql = null, instance = null, database = null, schema = null, cluster = null) {
      const count = this.tabs.length + 1
      const tabsWidth = this.$refs.tabs.clientWidth
      const maxTabs = parseInt(tabsWidth / 75)
      // console.log('count', count, 'tabsWidth', tabsWidth, 'maxTabs', maxTabs);
      if (count > maxTabs) {
        this.$dialogs.info(`Too many editors are open (${count - 1}) for this window.<br><br>Please close some of your open editors or resize this window.`)
        return
      }
      const label = _label || this.makeTabLabel()
      const key = uuidv1()
      const { currentTab } = this
      const settings = currentTab?.component ? functions.copyDeep(currentTab.component.editor.settings) : (currentTab?.settings ? currentTab.settings : {})
      if (settings.requestId) {
        delete settings.requestId
      }
      if (settings.database_id) {
        delete settings.database_id
      }
      if (sql) {
        settings.sql = sql
      } else {
        delete settings.sql
      }
      if (instance) {
        settings.instance = instance
      }
      if (database) {
        settings.database = database
      }
      if (cluster) {
        settings.cluster = cluster
      }
      if (schema) {
        settings.searchPath = [schema]
      }
      settings.expanded = {}
      if (!!settings.database && !!settings.instance) {
        const databaseObject = Database.query().where('instance_id', settings.instance).where('name', settings.database).first()
        if (databaseObject) {
          settings.expanded[`database:${databaseObject.database_id}`] = true
          settings.database_id = databaseObject.database_id
        }
      }
      const newTab = {
        label,
        key,
        settings,
        component: null
      }
      this.tabs.push(newTab)
      this.setTab(key)
      return newTab
    },

    setTab(tab) {
      if (!this.tabs.find(t => t.key === tab)) {
        console.error(`Cannot set tab to ${tab}; not present in active tab set`)
        return
      }
      this.tab = tab
      this.save()
    },

    closeTab(key) {
      const { tabs, currentTab } = this
      const index = tabs.findIndex(t => t.key === key)
      if (index >= 0) {
        const closingTab = tabs[index]
        tabs.splice(index, 1)
        this.tabs = tabs
        if (tabs.length === 0) {
          this.addTab()
        } else if (tabs.length === 1) {
          this.setTab(tabs[0].key)
        } else if (closingTab.key === currentTab.key) {
          let tab
          if (index >= tabs.length - 1) {
            tab = tabs[index - 1].key
          } else {
            tab = tabs[index].key
          }
          this.setTab(tab)
        }
        this.save()
      }
    },

    change(settings) {
      if (!settings || (settings instanceof Event)) {
        return // native event leaked in here
      }
      const { currentTab } = this
      if (!currentTab) {
        console.error('Cannot respond to @change; no selected tab (DEVELOPER BUG)')
        return
      }
      currentTab.settings = settings
      this.save()
    },

    saveImpl() {
      // Save the current tabs.
      const saved = this.tabs.map((tab) => {
        const { key, label, settings } = tab
        return { key, label, settings }
      })
      this.$store.set('query-editor/settings@tabs', functions.copyDeep(saved))
      this.$store.set('query-editor/settings@tab', this.tab)
    },

    openTabMenu(event) {
      window.setTimeout(() => this.$refs.tabmenu.open(event), 100)
    },

    closeAll() {
      this.tabs = []
      this.addTab()
    },

    closeOthers() {
      const { tabs, tab } = this
      this.tabs = tabs.filter(t => t.key === tab)
    },

    query({ databaseName, schemaName, tableName, sql }) {
      this.addTab(tableName, sql || `select * from ${schemaName}.${tableName} limit 1000`, null, databaseName)
    },

    execute(id) {
      if (id === 'file.new') {
        this.addTab()
      } else if (id === 'file.open') {
        this.openMode = id
        this.$refs.fileInput.click()
      } else if (id === 'file.import') {
        this.openMode = id
        this.$refs.fileInput.click()
      } else if (id === 'file.save') {
        this.fileSave()
      } else if (id === 'file.close') {
        this.closeTab(this.tab)
      } else if (id === 'file.closeall') {
        this.closeAll()
      } else if (id === 'file.closeothers') {
        this.closeOthers()
      } else if (id === 'yb.action.fullscreen') {
        this.fullscreen = !this.fullscreen
        if (this.fullscreen) {
          this.$refs.fullscreen.requestFullscreen()
        } else {
          document.exitFullscreen()
        }
      }
    },

    fileOpen($event) {
      const files = Array.from($event.target.files || $event.dataTransfer.files || [])
      files.forEach((file) => {
        const fileReader = new FileReader()
        fileReader.onload = () => {
          if (this.openMode === 'file.open') {
            this.addTab(file.name, fileReader.result)

            // This nutty bit of code is required to fix focus issues on firefox.
            // The problem is that monaco's view of the world with focus gets out
            // of sync after the file open dialog appears.  If we programmatically
            // create a new editor tab above, it thinks it has the focus, when
            // actually the tab that was open when the file dialog popped open
            // remains in focus due to keep alive.  The dance below causes a hidden
            // input control to gain focus, causing monaco to lose focus, then
            // programmatically gain it back, getting itself in sync.
            if (this.$refs.focusMe) {
              this.$refs.focusMe.focus()
              setTimeout(this.currentTab.component.focus, 1)
            }
          } else {
            this.currentTab.component.execute('paste', { text: fileReader.result })
          }
        }
        fileReader.readAsText(file)
      })
      $event.target.value = null
    },

    fileSave() {
      const { currentTab } = this

      // Save it as a blob.
      const blob = new Blob([currentTab.settings.sql], { type: 'application/sql;charset=utf-8' })
      FileSaver.saveAs(blob, currentTab.label + '.sql')

      this.$dialogs.info('File saved as ' + currentTab.label + '.sql')
    },

    fullscreenChange() {
      this.fullscreen = document.fullscreenElement === this.$refs.fullscreen
    },

    async handleLoad(_, prompt = true) {
      const { name, content, schema, cluster } = _
      let { database } = _

      // Check which instance to try.
      let instance
      if (this.currentTab && this.currentTab.settings?.instance) {
        instance = this.currentTab.settings.instance
      } else {
        await this.$store.dispatch('instance/populate')
        if (this.userInstances?.length > 1) {
          const instancePromise = new Promise((resolve, reject) => {
            this.showInstancesPromise = [resolve, reject]
          })
          try {
            instance = await instancePromise
            instance = instance?.id
          } catch (e) {
            return
          }
        } else if (this.userInstances?.length > 0) {
          instance = this.userInstances[0].id
        }
      }

      // Look for the database.
      if (prompt && database) {
        let databaseObject = Database.query().where('instance_id', instance).where('name', database).first()
        if (!databaseObject) {
          // Find a "can_write" database
          const databaseObjects = Database.query().where('instance_id', instance).get()
          databaseObject = databaseObjects.find(d => !!d.can_create && !d.is_readonly && !d.is_hot_standby)
          if (!databaseObject) {
            useDialogs().prompt('Yellowbrick Manager', `Could not locate the database for this sample (${database}).<br><br>Do you want to create this database for this sample?`, ['Cancel', 'Yes, Create Database'])
              .then(async (answer) => {
                if (answer === 1) {
                  await databaseObjectService.sql(instance, 'yellowbrick', `CREATE DATABASE ${database}`)
                  const instanceObject = this.userInstances.find(i => i.id === instance)
                  if (instanceObject) {
                    await this.$store.dispatch('databases/populate', instanceObject)
                  }
                  this.handleLoad(_, false)
                }
              })
            return
          } else {
            database = databaseObject.name
          }
        }
      }

      // Add the tab.
      this.addTab(name, content, instance, database, schema, cluster)
      this.load = null
    },

    dragEnd(event, source) {
      // Map the tabs.
      const tabMap = functions.indexBy(this.tabs, 'key')

      // Get the tab nodes sorted by drag/position.
      const childNodes = new Array(...this.$refs.tabsContainer.childNodes)
        .filter(cn => cn && cn.classList && cn.classList.contains('yb-tab'))
        .sort((c1, c2) => {
          let l1 = c1.getBoundingClientRect().left
          let l2 = c2.getBoundingClientRect().left
          if (c1 === source) { // source drag emphasis
            l1 -= 2
          }
          if (c2 === source) { // source drag emphasis
            l2 -= 2
          }
          return l1 - l2
        })
      false && console.log('childNodes', childNodes)

      // Updated tabs.
      const tabsUpdate = childNodes
        .map(cn => tabMap[cn.__vue__?.tabId])

      // Did it update?
      if (!functions.isEqual(this.tabs, tabsUpdate)) {
        console.log('tab order change', tabsUpdate.map(t => t.label))
        this.tabs = tabsUpdate
        this.save()
      }

      // Clear child positions.
      childNodes
        .forEach((cn) => {
          if (!!cn.style.left || !!cn.style.transform) {
            window.setTimeout(() => {
              false && console.log('clearing position for', cn.__vue__.value)
              cn.style.left = ''
              cn.style.top = ''
              cn.style.transform = ''
            }, 10)
          }
        })
    },

    dragMove(event, source) {
    },

    getOpIcon,
    getStatusColorClass
  }
}
</script>
