<template>
  <div class="yb-view">
    <div v-if="!editor.settings.zoomed" class="yb-view-header flex flex-col">
      <div class="settings-bar pointer-events-auto flex flex-row flex-nowrap items-center justify-between border-b border-yb-airforce">
        <div class="flex flex-row flex-nowrap flex-grow items-center h-8 space-x-0 lg:space-x-4 text-sm pl-0 cursor-pointer whitespace-nowrap">
          <yb-drop-down-search
            v-model="editor.settings.instance"
            data-test-id="query-page-instance-selector"
            search-label="Instances"
            :options="sortedInstances"
            item-id="id"
            item-label="name"
            list-classes=""
          >
            <yb-drop-down-button-label>
              <template #label>
                <div v-tooltip="hasInstance ? 'Instance: ' + selectedInstanceName : '(no instance)'" class="flex flex-col flex-nowrap leading-none">
                  <div class="flex flex-row flex-nowrap items-center space-x-1">
                    <yb-icon icon="instance" class="h-4 w-4" />
                    <div class="font-semibold">
                      Instance
                    </div>
                  </div>
                  <div>{{ hasInstance ? selectedInstanceName : '(connect to instance)' }}</div>
                </div>
              </template>
              >
            </yb-drop-down-button-label>

            <template #item="{ option: i, dataTestId }">
              <yb-instances-selector-item class="pl-4 pr-2" :option="i" :data-test-id="dataTestId" :selected="editor.settings.instance == i.id" />
            </template>
          </yb-drop-down-search>

          <yb-drop-down-search
            v-show="hasConnection"
            data-test-id="query-page-database-selector"
            search-label="Search Databases"
            :options="databases"
            item-id="name"
            item-label="name"
            list-classes=""
          >
            <yb-drop-down-button-label>
              <template #label>
                <div v-show="hasConnection" class="flex flex-col flex-nowrap leading-none">
                  <div class="flex flex-row flex-nowrap items-center space-x-1">
                    <yb-icon icon="database" class="h-4 w-4" />
                    <div class="font-semibold">
                      Database
                    </div>
                  </div>
                  <div>{{ hasConnection ? editor.settings.database : '(select database)' }}</div>
                </div>
              </template>
            </yb-drop-down-button-label>
            <template #item="{ option: d }">
              <yb-drop-down-item
                :key="d.database_id"
                :label="d.name"
                icon="database"
                :selected="editor.settings.database === d.name"
                :data-test-id="`query-page-database-selector-option-${d.name}`"
                @click="editor.settings.database = d.name, editor.settings.database_id = d.database_id"
              />
            </template>
          </yb-drop-down-search>

          <yb-drop-down-button
            v-show="hasConnection"
            v-tooltip="{content: editor.settings.cluster && useCluster(editor.settings.cluster), delay: 1000}"
            data-test-id="query-page-cluster-selector"
          >
            <template #label>
              <div v-show="hasConnection" class="flex flex-col flex-nowrap leading-none">
                <div class="flex flex-row flex-nowrap items-center space-x-1">
                  <yb-icon icon="hierarchy" class="h-4 w-4" />
                  <div class="font-semibold">
                    Cluster
                  </div>
                </div>
                <div>{{ editor.settings.cluster || '(use default)' }}</div>
              </div>
            </template>
            <yb-drop-down-item
              key="(default)"
              label="(use default)"
              icon="delete-circle"
              :selected="editor.settings.cluster === null"
              data-test-id="query-page-cluster-default"
              @click="editor.settings.cluster = null"
            />
            <hr>

            <yb-clusters-selector-item
              v-for="c in clusters"
              :key="c.cluster_name"
              data-test-id="query-page-cluster-selector-option"
              :selected="editor.settings.cluster === c.cluster_name"
              class="pl-4 pr-2"
              :option="c"
              @click="editor.settings.cluster = c.cluster_name"
            />
          </yb-drop-down-button>

          <yb-drop-down-button
            v-show="hasConnection"
            v-tooltip="{content: setSearchPath(editor.settings.searchPath), delay: 1000}"
            :multiple="editor.settings.searchPath"
            :links="links"
            data-test-id="query-page-schema-selector"
            popup-class="overflow-hidden w-72"
            @select="select"
          >
            <template #label>
              <div v-show="hasConnection" class="flex flex-col flex-nowrap leading-none">
                <div class="flex flex-row flex-nowrap items-center space-x-1">
                  <yb-icon icon="schema" class="h-4 w-4" />
                  <div class="font-semibold">
                    Search Path
                  </div>
                </div>
                <div class="truncate" style="max-width: 100px">
                  {{ hasConnection ? editor.settings.searchPath.join(', ') || '(none)' : '(select search path)' }}
                </div>
              </div>
            </template>

            <recycle-scroller
              v-slot="{ item: s }"
              class="h-full w-auto select-none scroller"
              :items="unselectedSchemas"
              :item-size="28"
              key-field="schema_id"
            >
              <yb-drop-down-item
                :key="s.schema_id"
                icon="schema"
                :data-test-id="`query-page-schema-selector-option-${s.name}`"
                @click.prevent="select('item', s.name)"
              >
                <div class="flex flex-row flex-nowrap items-center w-full">
                  {{ s.name }}
                </div>
              </yb-drop-down-item>
            </recycle-scroller>
          </yb-drop-down-button>

          <yb-drop-down-search
            v-show="hasConnection"
            v-model="editor.settings.role"
            v-tooltip="{content: hasConnection && setRole(editor.settings.role), delay: 1000}"
            data-test-id="query-page-role-path-selector"
            search-label="Search Roles"
            :options="allowedRoles"
            item-id="name"
            item-label="name"
            list-classes=""
          >
            <yb-drop-down-button-label>
              <template #label>
                <div v-show="hasConnection" class="flex flex-col flex-nowrap leading-none">
                  <div class="flex flex-row flex-nowrap items-center space-x-1">
                    <yb-icon icon="user_share" class="h-4 w-4" />
                    <div class="font-semibold">
                      Role
                    </div>
                  </div>
                  <div>{{ hasConnection ? editor.settings.role : '(select role)' }}</div>
                </div>
              </template>
            </yb-drop-down-button-label>

            <template #item="{ option: r }">
              <yb-drop-down-item
                :key="r.id"
                :icon="r.__type || 'user'"
                class="w-full"
                :selected="editor.settings.role === r.name"
                :selection-indicator="false"
                data-test-id="query-page-role-path-selector-option"
              >
                <div class="flex flex-row flex-nowrap items-center w-full">
                  {{ r.name }}
                </div>
              </yb-drop-down-item>
            </template>
          </yb-drop-down-search>

          <yb-drop-down-button
            v-show="hasConnection"
            v-if="false"
            popup-class="overflow-hidden w-72"
          >
            <template #label>
              <div v-show="hasConnection" class="flex flex-col flex-nowrap leading-none">
                <div class="flex flex-row flex-nowrap items-center space-x-1">
                  <yb-icon icon="user_share" class="h-4 w-4" />
                  <div class="font-semibold">
                    Role
                  </div>
                </div>
                <div>{{ hasConnection ? editor.settings.role : '(select role)' }}</div>
              </div>
            </template>
            <recycle-scroller
              v-slot="{ item: r }"
              class="h-full w-auto select-none scroller"
              :items="allowedRoles"
              :item-size="28"
              key-field="id"
            />
          </yb-drop-down-button>
        </div>
        <div class="flex flex-row flex-nowrap items-center space-x-1 h-8 pr-2">
          <yb-button
            v-show="hasInstance"
            v-tooltip="'Disconnect'"
            class="border yb-border-content yb-body-color rounded-sm px-1.5"
            icon="disconnect"
            icon-classes=""
            data-test-id="query-page-cancel-button"
            @click="disconnect"
          />

          <yb-button
            v-show="hasConnection"
            v-tooltip="'Reconnect/refresh'"
            class="border yb-border-content yb-body-color rounded-sm px-1.5"
            icon="refresh"
            icon-classes=""
            data-test-id="query-page-refresh-button"
            @click="refresh"
          />

          <div
            v-show="hasConnection"
            class="mr-4 text-sm cursor-pointer flex items-center border yb-border-content py-0.5 px-2 yb-body-color rounded-sm hover:bg-yb-rollover-light hover:text-yb-gray-dark"
            :class="{ 'yb-button-down': history }"
            data-test-id="history"
            @click="history = !history"
          >
            <yb-icon icon="history" class="yb-symbol-icon-sm mr-1" />
            History
          </div>
        </div>
      </div>
    </div>
    <div :id="'content-' + id" class="yb-view-content border-l yb-border-content-editor pointer-events-auto relative">
      <splitpanes ref="splitH" class="yb-bg-content" @resize="editor.settings.splitH = $event.map(item => item.size)">
        <pane v-show="editor.settings.schemaBrowser" :size="editor.settings.splitH[0]" :min-size="0">
          <yb-object-tree
            v-show="hasInstance"
            ref="tree"
            :selected-instance-id="selectedInstance"
            :selected-instance-name="selectedInstanceName"
            :refreshing="refreshing"
            @query="query"
            @explore="explore"
            @show-table="showTable"
            @focus="focus"
            @execute="executeEvent"
            @select="treeSelect"
            @refresh="load"
          />
          <label v-if="!hasInstance" class="p-3">
            No connection
          </label>
        </pane>
        <pane :size="editor.settings.splitH[1]" :min-size="0">
          <splitpanes ref="splitV" class="yb-bg-content dark:bg-yb-gray-alt-dark" horizontal @resize="editor.settings.splitV = $event.map(item => item.size)">
            <pane :size="editor.settings.splitV[0]" :min-size="20" class="yb-view relative">
              <div class="yb-view-header flex flex-col flex-nowrap mb-3">
                <!-- For test: one of these hidden elements will be present when query is running only (query-run-running), otherwise the other one (query-run-not-running) -->
                <div v-if="editor.loading" class="w-0 h-0 hidden" data-test-id="query-run-running" />
                <div v-else class="w-0 h-0 hidden" data-test-id="query-run-not-running" />

                <div class="flex flex-row flex-nowrap items-center space-x-2.5 px-2 py-2 yb-body-text yb-bg-content border-b yb-border-content dark:border-yb-gray-medium shadow dark:bg-yb-gray-alt-dark">
                  <yb-drop-down-button2
                    v-show="!editor.loading"
                    :container="'#content-' + id"
                    align-right
                    label="Run"
                    icon="play"
                    :open-click-arrow="true"
                    base-button-class="py-1.5"
                    button-classes="yb-button-primary rounded-md text-sm"
                    icon-classes="yb-button-icon-md text-white fill-current"
                    :disabled="!hasConnection || !isHealthy"
                    data-test-id="query-run-button-wrapper"
                    @click="runWrapper"
                  >
                    <div class="w-96">
                      <yb-drop-down-key
                        icon="play"
                        command="yb.run"
                        label="Run"
                        :disabled="editor.loading"
                        data-test-id="query-run-button"
                        @click="execute"
                      />

                      <div class="pl-0">
                        <hr class="my-2 ml-6 mr-2">
                        <p v-show="!selected" class="px-3 ml-4 text-xs">
                          Select a portion of query text to enable run selected
                        </p>

                        <yb-drop-down-key
                          icon="play"
                          command="yb.run.selected"
                          label="Run Selected"
                          :disabled="editor.loading || !selected"
                          data-test-id="query-run-selected"
                          @click="execute"
                        />
                      </div>
                    </div>
                  </yb-drop-down-button2>

                  <yb-button
                    v-show="editor.loading"
                    label="Cancel"
                    icon="delete"
                    :disabled="!canCancelQuery"
                    button-classes="yb-button"
                    icon-classes="yb-button-icon-md text-red-500"
                    data-test-id="query-run-cancel"
                    @click="cancel"
                  />

                  <div class="h-4 self-center mx-2 bg-yb-gray-medium" style="width: 1px;" />

                  <yb-file-menu class="pointer-events-auto" :disabled="false && editor.loading" :container="'#content-' + id" @click="execute" />
                  <yb-edit-menu class="pointer-events-auto" :disabled="editor.loading" :container="'#content-' + id" @click="execute" />
                  <yb-action-menu data-test-id="action-menu" class="pointer-events-auto" :disabled="editor.loading" :container="'#content-' + id" @click="execute" />

                  <yb-button
                    label="Sample Catalog"
                    icon="graph_square"
                    action-link
                    :disabled="editor.loading"
                    data-test-id="sample-catalog"
                    @click="samples"
                  />

                  <div v-show="editor.loading" class="flex flex-row flex-nowrap flex-1 justify-end items-center space-x-2">
                    <h5 v-show="(editor.queryEnd - editor.queryStart) > 1000" class="text-xs">
                      (executed {{ humanizeElapsedTime(editor.queryEnd - editor.queryStart) }}, @{{ humanizeMs(editor.queryEnd - editor.queryStart) }})
                    </h5>
                    <div class="h-6 w-6">
                      <yb-processing class="w-full h-full" />
                    </div>
                  </div>
                </div>
                <yb-error :show-error="!!editor.showError && (!!editor.message || !!editor.error)" :error-message="(editor.message && (editor.message + editor.showRowsetDetail)) || formatErrorMessage(editor.error)" :is-message="!!editor.message" @close="editor.showError = false" />
                <yb-error :show-error="!!queryBlocked" :error-message="queryBlocked" is-banner :show-close="false" />
              </div>

              <yb-query-editor
                ref="editor"
                class="yb-view-content"
                :disabled="editor.loading"
                data-test-id="query-editor"
                @ready="ready"
                @change="change"
                @selected="selected = $event"
                @execute="execute"
              />
            </pane>

            <pane :size="editor.settings.splitV[1]" :min-size="0" class="yb-bg-content relative dark:bg-yb-gray-alt-dark">
              <div class="absolute top-0 right-0 mr-0 mt-2 flex flex-row flex-nowrap items-center">
                <yb-button
                  v-show="editor && editor.rowset && editor.rowset.execId"
                  class="yb-button mr-2"
                  action-link
                  icon="nav_options"
                  label="Details"
                  data-test-id="query-details"
                  @click="showQueryDetails = true"
                />

                <div
                  v-if="editor && editor.rowset && editor.rowset.execId"
                  v-yb-clipboard:text="editor.rowset.execId"
                  v-tooltip="'Copy query id to clipboard'"
                  class="circle text-xs opacity-70 cursor-pointer mr-2"
                >
                  ID
                </div>

                <yb-drop-down-button
                  v-if="editor.rowset && (typeof editor.rowset.index === 'number') && editor.rowsets && editor.rowsets.length > 1"
                  icon="sql-result"
                  button-classes="yb-border-content border rounded-sm"
                  align-right
                  data-test-id="query-page-results-selector"
                >
                  <template #label>
                    <span class="inline-block py-0 px-1 mx-1 h-4 text-xs border border-yb-gray-medium rounded" data-test-id="query-results" style="line-height: 0.9rem">{{ editor.rowset.index + 1 }} of {{ editor.rowsets.length }}</span>Query Results
                  </template>
                  <yb-drop-down-item
                    v-for="(rowset, index) in editor.rowsets"
                    :key="index"
                    :selected="editor.rowset && index == editor.rowset.index"
                    link-class="p-0.5"
                    :data-test-id="'query-page-results-selector-' + (index + 1)"
                    @click="editor.rowset = rowset, editor.showError = !!editor.rowset.error"
                  >
                    <div class="inline-block w-6 text-right mr-2">
                      {{ index + 1 }}
                    </div>
                    <div class="inline-block mr-2">
                      {{ rowset.command || 'UNKNOWN' }}
                    </div>
                  </yb-drop-down-item>
                </yb-drop-down-button>
              </div>
              <tabs ref="tabsRef" :key="'results:' + (editor.rowset && editor.rowset.execId)" class="h-full">
                <tab title="Results">
                  <keep-alive>
                    <yb-query-results-data data-test-id="query-page-results-content" />
                  </keep-alive>
                </tab>
                <tab title="Messages">
                  <keep-alive>
                    <yb-query-results-messages data-test-id="query-page-messages-content" />
                  </keep-alive>
                </tab>
                <tab v-if="false" title="Plan">
                  <keep-alive>
                    <yb-query-results-plan data-test-id="query-page-plan-content" historical :query-id-param="editor.rowset && editor.rowset.execId" :instance-id="selectedInstance" />
                  </keep-alive>
                </tab>
                <tab v-if="false" title="Statistics">
                  <keep-alive>
                    <yb-query-results-statistics data-test-id="query-page-statistics-content" historical :query-id-param="editor.rowset && editor.rowset.execId" :instance-id="selectedInstance" />
                  </keep-alive>
                </tab>
                <tab title="Explore">
                  <keep-alive>
                    <yb-query-results-explorer data-test-id="query-page-explorer-content" />
                  </keep-alive>
                </tab>
              </tabs>
            </pane>
          </splitpanes>
        </pane>
      </splitpanes>
    </div>

    <transition name="drawer">
      <yb-modal
        v-if="showQueryDetails && editor.rowset && editor.rowset.execId"
        show
        title="Query Details"
        type="form"
        layout="drawer"
        dialog-classes=""
        drawer-width="yb-side-xl"
        data-test-id="show-query-details"
        @close="showQueryDetails = false"
      >
        <template #default="{ dialogController }">
          <yb-query-details
            v-if="showQueryDetails"
            :dialog-controller="dialogController"
            :query-id="editor.rowset && editor.rowset.execId"
            query-text="Loading..."
            :instance-id="selectedInstance"
            :instance-name="selectedInstanceName"
            :historical="true"
          />
        </template>
      </yb-modal>
    </transition>

    <transition name="drawer">
      <yb-modal
        v-if="explorer != null"
        show
        :title="exploreTableTitle"
        icon="table"
        type="form"
        layout="drawer"
        dialog-classes=""
        drawer-width="yb-side-xl"
        data-test-id="explore-table-data"
        @close="explorer = null"
      >
        <yb-explore-rowset :key="explorer.batch" :editor="explorer" @more="more" />
      </yb-modal>
    </transition>

    <transition name="drawer">
      <yb-modal
        v-if="history"
        show
        title="Query History"
        icon="history"
        type="form"
        layout="drawer"
        dialog-classes="w-2/3"
        drawer-width="w-2/3"
        content-classes="pl-3 pb-3 pr-3"
        data-test-id="query-history-header"
        @close="history = false"
      >
        <yb-content-history
          :id="id"
          :instance="selectedInstanceObject"
          :database="editor.settings.database"
          @run="historyRun"
          @edit="historyEdit"
          @open="historyOpen"
        />
      </yb-modal>
    </transition>
  </div>
</template>

<script>
import { defineAsyncComponent, reactive } from 'vue'
import { Splitpanes, Pane } from 'splitpanes'
import YbObjectTree from './ObjectTree.vue'
import YbQueryEditor from './Editor.vue'
import YbFileMenu from './EditorFileMenu.vue'
import YbEditMenu from './EditorEditMenu.vue'
import YbActionMenu from './EditorActionMenu.vue'
import YbDropDownKey from './DropDownKey.vue'
import YbContentHistory from './ContentHistory.vue'
import * as editorHelper from './editorHelper'
import { jolokiaService, mbeans, databaseObjectService, tasksService } from '@/services'
import { functions } from '@/util'
import { rowsetLimit } from '@/services/constants'
import { NotFound } from '@/services/errors'

import SvgClose from '@/assets/svg/close.svg'
import Instance from '@/models/Instance'
import Database from '@/models/Database'
import Cluster from '@/models/Cluster'
import Schema from '@/models/Schema'
import Role from '@/models/Role'
import { humanizeMs, humanizeElapsedTime } from '@/filters'
import useInstancesLoader from '@/app/instance/InstancesLoader'
import YbInstancesSelectorItem from '@/app/instance/InstancesSelectorItem.vue'
import YbClustersSelectorItem from '@/app/instance/ClustersSelectorItem.vue'
import YbQueryDetails from '@/app/instance/QueryDetails.vue'

const defaultSql = '-- Type your query below\n'

export default {
  props: {
    settings: Object,
    id: String
  },
  setup () {
    return {
      ...useInstancesLoader()
    }
  },
  components: {
    Splitpanes,
    Pane,
    YbObjectTree,
    YbQueryEditor,
    YbQueryResultsData: defineAsyncComponent(() => import('./ResultsData.vue')),
    YbQueryResultsExplorer: defineAsyncComponent(() => import('./ResultsExplorer.vue')),
    YbQueryResultsMessages: defineAsyncComponent(() => import('./ResultsMessages.vue')),
    YbQueryResultsPlan: defineAsyncComponent(() => import('./ResultsPlan.vue')),
    YbQueryResultsStatistics: defineAsyncComponent(() => import('./ResultsStatistics.vue')),
    YbExploreRowset: defineAsyncComponent(() => import('./ExploreRowset.vue')),
    YbFileMenu,
    YbEditMenu,
    YbActionMenu,
    YbDropDownKey,
    SvgClose,
    YbContentHistory,
    YbInstancesSelectorItem,
    YbClustersSelectorItem,
    YbQueryDetails
  },
  provide() {
    return {
      editor: this.editor
    }
  },
  data() {
    const defaultRole = this.$store.get('global/settings@user.email')

    // Merge default settings with prop settings.
    const settings = {
      ...{
        autoformat: false,
        instance: null,
        database: null,
        database_id: 0,
        searchPath: ['public'],
        role: defaultRole,
        sql: defaultSql,
        schemaBrowser: true,
        zoomed: false,
        zoomSizes: null,
        splitH: [30, 70],
        splitV: [33, 66],
        splitT: [60, 40]
      },
      ...(this.settings || {})
    }
    settings.key = this.id

    if (!(settings.searchPath instanceof Array)) {
      settings.searchPath = []
    }

    return {
      editor: {
        rowset: null,
        rowsets: null,
        error: null,
        loading: false,
        databaseModelLoading: true,
        editorInstanceId: null,
        settings,
        queryStart: 0,
        queryEnd: 0,
        functions: null,
        showError: false,
        showRowsetDetail: ''
      },
      selected: false,
      links: [
        { label: 'Public', id: 'public' },
        { label: 'Default', id: 'default' },
        { label: 'All', id: 'all' },
        { label: 'None', id: 'none' }
      ],
      explorer: null,
      exploreTableTitle: null,
      defaultRole,
      history: false,
      refreshing: false,
      canCancelQuery: false,
      showQueryDetails: false
    }
  },
  computed: {
    sortedInstances() {
      if (!this.instances) { return [] }
      return this.instances.sort((a, b) => a.name.localeCompare(b.name))
    },
    selectedInstance() {
      return this.editor?.settings?.instance
    },
    selectedInstanceName() {
      const { selectedInstanceObject } = this
      return selectedInstanceObject && selectedInstanceObject.name
    },
    selectedInstanceId() {
      const { selectedInstanceObject } = this
      return selectedInstanceObject && selectedInstanceObject.id
    },
    selectedInstanceObject() {
      const { selectedInstance } = this
      return Instance.query().whereId(selectedInstance).first()
    },
    hasInstance() {
      return !!this.selectedInstance && !!this.selectedInstanceObject
    },
    hasConnection() {
      return this.hasInstance && !!this.editor?.settings?.database
    },
    isHealthy() {
      return this.hasInstance && ['RUNNING', 'HEALTHY'].includes(this.selectedInstanceObject?.status?.type)
    },
    searchPath() {
      if (this.editor.settings.searchPath.length === 0) {
        return '(none)'
      } else {
        let result = this.editor.settings.searchPath[0]
        if (this.editor.settings.searchPath.length > 1) {
          result += ` (+ ${this.editor.settings.searchPath.length - 1} more)`
        }
        return result
      }
    },
    limit() {
      return Number(this.$store.get('settings/editor@limit')) || 1000
    },
    allowedSchemas() {
      return Schema.query()
        .where('instance_id', this.selectedInstance)
        .where('database_id', this.editor?.settings?.database_id)
        .get()
        .filter(Schema.filterCommon)
        .sort(functions.sortByName)
    },
    unselectedSchemas() {
      const { editor } = this
      return this.allowedSchemas.filter(s => s.database_id === editor.settings.database_id && !editor.settings.searchPath.includes(s.name))
    },
    allowedRoles() {
      const defaultRole = this.$store.get('global/settings@user.email')
      return Role.query()
        .where('instance_id', this.selectedInstance)
        .get()
        .filter(role => !!role.is_member || role.name === defaultRole)
        .sort(functions.sortByName)
    },
    databases() {
      return Database.query().where('instance_id', this.selectedInstance).get().sort(functions.sortByName)
    },
    clusters() {
      return Cluster.query().where('instance_id', this.selectedInstance).get().sort(functions.sortByName)
    },
    queryBlocked() {
      const { editor, defaultRole } = this
      const tag = `ym:${defaultRole}:tab:${editor.settings.key}`
      const tasks = tasksService?.taskState?.tasks || []
      const task = tasks.find(t => String(t.tags).indexOf(tag) >= 0)
      return task?.blocked
    }
  },
  watch: {
    'editor.settings.instance': async function instanceChanged(_) {
      if (!_) {
        return
      }
      this.editor.settings.database = null
      this.editor.settings.searchPath.splice(0, this.editor.settings.searchPath.length)
      this.editor.settings.searchPath.push('public')
      this.editor.settings.role = this.defaultRole
      this.editor.settings.cluster = null
      this.load()
    },
    'editor.settings.database': async function databaseChanged(_) {
      this.editor.settings.searchPath.splice(0, this.editor.settings.searchPath.length)
      this.editor.settings.searchPath.push('public')
      this.editor.settings.role = this.defaultRole
      this.loadDatabase(this.editor.settings.database)
    },
    'editor.settings': {
      deep: true,
      handler: function settingsChanged(_) {
        this.$emit('change', _)
      }
    },
    'editor.settings.zoomed': function zoomChanged() {
      this.updateZoom()
    },
    'editor.settings.sql': function sqlChanged(_) {
      this.maybeZoom()
      this.editor.showError = false
      this.editor.message = null
    }
  },
  async mounted() {
    this.maybeZoom()

    await this.$store.dispatch('instance/populate')

    if (!this.selectedInstance) {
      if (this.instances?.length === 1) {
        this.editor.settings.instance = this.instances[0].id
      }
    }

    await this.load()
  },
  activated() {
    this.activate()
  },
  methods: {
    humanizeMs,
    humanizeElapsedTime,
    ...editorHelper,
    ready() {
      // If we are just getting started.....
      if (this.editor.settings.sql === defaultSql) {
        this.$nextTick(() => this.execute('command.cursorBottom'))
      }
    },
    async load() {
      const { selectedInstance } = this
      if (!selectedInstance) {
        return
      }
      this.resumeResponse()
      this.$store.set('global/loading', true)
      this.editor.databaseModelLoading = true
      try {
        try {
          // Load the model.
          await databaseObjectService.populate(selectedInstance, this.editor?.settings?.database)
        } catch (e) {
          if (!(e instanceof NotFound)) {
            throw e
          } else {
            this.editor.settings.database = null
          }
        }

        // If we don't have a database set; try picking the first (alpha) that is non-yellowbrick db.
        if (!this.editor.settings.database) {
          let databases = this.databases.filter(d => d.name !== 'yellowbrick').sort(functions.sortByName)
          if (databases.length === 0 && this.databases.length !== 0) {
            databases = this.databases
          }
          if (databases.length > 0) {
            this.editor.settings.database = databases[0].name
            this.editor.settings.database_id = databases[0].database_id
            if (typeof this.editor.settings.expanded === 'object') {
              this.editor.settings.expanded[`database:${databases[0].database_id}`] = true
            }
          }
        }
      } catch (e) {
        console.log('Could not load model from', selectedInstance, this.editor?.settings?.database)
        console.error(e)
        if (!this.editor.showError) {
          this.editor.showError = true
          this.editor.error = e
          this.editor.message = null
        }
      } finally {
        this.$store.set('global/loading', false)
        this.editor.databaseModelLoading = false
      }
    },

    async activate() {
      const { selectedInstance, selectedInstanceId, selectedInstanceObject } = this
      if (!selectedInstance || !selectedInstanceId || !selectedInstanceObject) {
        return
      }

      // Refresh database model for all database objects.
      const populated = this.databases.some(d => !!d.populated)
      if (!populated) {
        try {
          this.databaseModelLoading = true
          await this.load()
        } finally {
          this.databaseModelLoading = false
        }

      // Refresh database model for clusters.
      } else if (this.clusters.length === 0) {
        await databaseObjectService.connect(selectedInstanceId)
        const clusters = await databaseObjectService.populateClusters(this.editor?.settings?.database, selectedInstanceObject)
        Cluster.insertWithInstance(selectedInstance, { data: clusters })
      }

      // Are we running a query?  If so, check if we can still find it.  If not, switch running state.
      if (this.editor?.loading) {
        const tagsRE = new RegExp(`ym:${this.defaultRole}:tab:${this.editor.settings.key}`)
        const thisQuery = tasksService.taskState.tasks.find(q => String(q.tags).match(tagsRE))
        if (!thisQuery) {
          // No longer running a query.
          this.editor.loading = false
          if (this.pingHandle) {
            window.clearInterval(this.pingHandle)
            delete this.pingHandle
          }
        }
      } else {
        this.resumeResponse()
      }
    },

    async loadDatabase(database) {
      const { selectedInstance } = this
      if (!selectedInstance) {
        return
      }
      this.$store.set('global/loading', true)
      try {
        // Load the model.
        await databaseObjectService.populate(selectedInstance, database)

        // Check our current search path if it needs updating
        if (database === this.editor.settings.database && database !== this.editor.settings.searchPathDatabase) {
          const searchPath = await databaseObjectService.sql(selectedInstance, database, 'SHOW SEARCH_PATH')
          if (searchPath?.rows?.length > 0 && typeof searchPath.rows[0].search_path === 'string') {
            this.editor.settings.searchPath.splice(0, this.editor.settings.searchPath.length)
            const paths = searchPath.rows[0].search_path.replace(/^"(.*?)"$/, '$1').split(/,/g)
            const schemas = new Set(this.allowedSchemas.map(s => s.name))
            schemas.add('public')
            paths
              .filter(path => schemas.has(path))
              .forEach(path => this.editor.settings.searchPath.push(path.trim()))
            this.editor.settings.searchPathDatabase = database
          }
        }
      } catch (e) {
        if (!(e instanceof NotFound)) {
          console.log('Could not load model from', selectedInstance, database)
          console.error(e)
          if (!this.editor.showError) {
            this.editor.showError = true
            this.editor.error = e
            this.editor.message = null
          }
        }
      } finally {
        this.$store.set('global/loading', false)
      }
    },

    async treeSelect(treeItem) {
      if (treeItem?.type === 'database') {
        await this.loadDatabase(treeItem.name)
      }
    },

    async run($event, selection) {
      if (!this.hasConnection) {
        this.$dialogs.warning('Please connect to an instance to run query')
        return
      }
      if (!this.isHealthy) {
        this.$dialogs.warning('The selected instance is not ready to run queries')
        return
      }
      this.createPing()
      try {
        const now = +new Date()
        this.editor.showError = false
        this.editor.message = null
        this.editor.queryStart = now
        this.editor.queryEnd = now
        this.editor.loading = true
        this.editor.showRowsetDetail = ''
        let { sql } = this.editor.settings
        if (selection) {
          sql = this.$refs.editor.selected()
        }
        this.$track('query.run.start')
        await this.runQuery(sql, this.editor)
        if (this.$refs.tabsRef) {
          this.$refs.tabsRef.selectedIndex = (!!this.editor.error && 1) || 0
        }
        if (!!this.editor.settings.zoomed && !!this.editor.settings.zoomSizes) {
          const { splitV: { instance: instanceV } } = this.$refs
          instanceV.setSizes(this.editor.settings.zoomSizes[1])
        }

        // Don't have an error and do have a rowset?
        if (this.editor.rowsets?.length > 0) {
          // Display summary message.
          if (!this.editor.showError) {
            this.editor.showError = true
            if (this.editor.rowsets?.length === 1) {
              this.editor.message = this.editor.rowsets[0].command
              if (this.editor.rowsets[0].rows?.length > 0 && this.editor.message !== 'INSERT') {
                this.editor.message += ' ' + this.editor.rowsets[0].rows?.length
              }
            } else {
              this.editor.message = 'Executed ' + this.editor.rowsets.length + ' statements'
            }
          }

          this.analyzeResults()
        }
      } catch (e) {
        if (e instanceof Error) {
          console.error('Error occurred executing query')
          console.error(e)
        }
        this.editor.rowset = null
        this.editor.rowsets = null
        if (!this.editor.showError) {
          this.editor.error = e
          this.editor.showError = true
          this.editor.message = null
        }
        if (this.$refs.tabsRef) {
          this.$refs.tabsRef.selectedIndex = 1
        }
        this.$track('query.run.fail')
      } finally {
        this.editor.loading = false
        this.editor.queryEnd = +new Date()
        this.destroyPing()
        this.$track('query.run.end')
      }
    },

    createPing() {
      if (this.pingHandle) {
        window.clearInterval(this.pingHandle)
        delete this.pingHandle
      }
      this.pingHandle = window.setInterval(this.ping.bind(this), 100)
    },

    destroyPing() {
      window.clearInterval(this.pingHandle)
      delete this.pingHandle
    },

    async analyzeResults() {
      if (!this.editor.rowsets) {
        return
      }

      // Analyze results; if any did a 'CREATE|ALTER|DROP', re-load the db.
      let reload = false
      let databaseChange = false
      this.editor.rowsets.forEach((rowset) => {
        reload = reload || !!String(rowset?.command).match(/^(CREATE|ALTER|DROP)/)
        if (String(rowset?.command).match(/SELECT/) && rowset?.columns?.length === 0 && rowset?.rows?.length === 0 && rowset?.updateCount > 0) {
          // This is a CTAS, which shows as "SELECT {insertedcount}";
          reload = true
        }
        databaseChange = databaseChange || !!String(rowset?.command).match(/DATABASE/)
      })
      if (databaseChange) {
        this.refresh(true)
      } else if (reload) {
        const { database } = this.editor.settings
        const { selectedInstance } = this

        // Re-load the model.
        this.refreshing = true
        try {
          await databaseObjectService.repopulate(selectedInstance, database)
        } finally {
          this.refreshing = false
        }
      }
    },

    async refresh(evictDatabases) {
      const { expanded } = this.editor.settings
      const { selectedInstance } = this
      try {
        // Gather database and schema objects, to get a set of database objects to refresh.
        const expandedDatabases = new Set(Object.keys(expanded).filter(k => k.match(/^(database|schema):/)).map(k => parseInt(k.split(/:/)[1])))

        // Need a map of database names.
        const databaseMap = functions.mapValues(functions.indexBy(this.databases, 'database_id'), d => d.name)

        // Evict all databases to cause complete reload.
        if (evictDatabases) {
          Database.delete(item => item.instance_id === selectedInstance)
        }

        // Load each of the databases we have expanded.
        this.editor.databaseModelLoading = true
        let populated

        await Promise.all([...expandedDatabases].map((database_id) => {
          const databaseName = databaseMap[database_id]
          if (databaseName) {
            populated = true
            return databaseObjectService.repopulate(selectedInstance, databaseName)
          } else {
            return Promise.resolve(false)
          }
        }))
        if (!populated) {
          await this.load()
        }
      } finally {
        this.editor.databaseModelLoading = false
      }
    },

    async runQuery(sql, editor) {
      if (!this.hasConnection) {
        this.$dialogs.warning('Please connect to an instance to run query')
        return
      }
      if (!this.isHealthy) {
        this.$dialogs.warning('The selected instance is not ready to run queries')
        return
      }

      // To: sanitize search path: DB must contain each schema.
      const schemas = new Set(this.allowedSchemas.map(s => s.name))

      // set search path.
      const searchPath = schemas && editor.settings.searchPath.filter(schema => schemas.has(schema)).join(',')

      // Check query is here.
      if (!sql) {
        this.$dialogs.info('Please enter a query to continue')
        return
      }
      sql = sql.trim()
      if (!sql) {
        this.$dialogs.info('Please enter a query to continue')
        return
      }

      // Setup the session.
      sql = `SET ENABLE_QUERY_TRACE TO 'ON';\n` + sql
      if (searchPath) {
        sql = `SET SEARCH_PATH TO ${searchPath};\n` + sql
      }
      if (!!editor.settings.role && editor.settings.role !== this.defaultRole) {
        sql = `SET ROLE TO "${editor.settings.role}";\n` + sql
      }
      if (editor.settings.key) {
        sql = `SET ybd_query_tags TO 'ym:${this.defaultRole}:tab:${editor.settings.key}';\n` + sql
      }
      if (editor.settings.cluster) {
        sql = `USE CLUSTER "${editor.settings.cluster}";\n` + sql
      }

      // Issue the query.
      const { selectedInstanceId } = this
      const limit = Math.min(rowsetLimit, this.limit || rowsetLimit)
      await databaseObjectService.connect(selectedInstanceId)
      jolokiaService.start()
      jolokiaService.flush()
      const asyncTimeout = 30 * 1000
      jolokiaService.timeout(2 * asyncTimeout) // we can wait a long time
      jolokiaService.collectionSize(limit)
      jolokiaService.depth(1000)
      const optionsSupported = !this.selectedInstanceObject.softwareVersion || parseFloat(this.selectedInstanceObject.softwareVersion) >= 5.2 // If version is not set, assume dev mode and > 5.2
      const query = optionsSupported
        ? jolokiaService.execute(mbeans.datasources, 'queryExOptions', [editor.settings.database, sql, 'ym', limit, {
          errorDetail: true,
          convertToColumnar: true,
          asyncTimeout: 1000 // short initial poll to get request id
        }])
        : jolokiaService.execute(mbeans.datasources, 'queryEx', [editor.settings.database, sql, 'ym', limit])
      jolokiaService.flush()

      // Start polling for state on this instance.
      tasksService.pollInstance(this.selectedInstanceObject, editor.settings.database)

      // Now poll the response of the query.
      await this.pollResponse(query, asyncTimeout, editor)
    },

    async pollResponse(query, asyncTimeout, editor) {
      const { selectedInstanceId, selectedInstanceObject } = this
      const limit = Math.min(rowsetLimit, this.limit || rowsetLimit)
      const { database } = editor.settings

      // Wait for execution.
      let rowset
      while (true) {
        rowset = await query
        if (rowset.requestId) { // Did we get a async request?
          editor.settings.requestId = rowset.requestId
          await databaseObjectService.connect(selectedInstanceId)
          jolokiaService.start()
          jolokiaService.flush()
          jolokiaService.timeout(2 * asyncTimeout) // we can wait a long time
          jolokiaService.collectionSize(limit)
          query = jolokiaService.execute(mbeans.datasources, 'pollResponse', [rowset.requestId, asyncTimeout])
          jolokiaService.flush()
          editor.queryStart = rowset.timeStart
          asyncTimeout = 30 * 1000 // Might have been smaller to resume poll.
        } else {
          break
        }
      }
      this.editor.settings.requestId = null
      if (selectedInstanceObject && database) {
        tasksService.addCompletedQueriesByRowset(selectedInstanceObject, database, rowset)
      }

      // Organize secondary rowsets into array.
      const rowsets = [rowset]
      if (rowset.rowSets) {
        rowsets.push(...rowset.rowSets)
        delete rowset.rowSets
      }
      let errorIndex
      rowsets.forEach((r, index) => {
        // Index the rowset and record the index of the first erroring rowset
        r.index = index
        if (r.error) {
          errorIndex = index
        }

        // Re-assemble rowsets from columnar form.
        this.uncolumnarize(r)
      })

      // Tune in to error if there is one.
      if (typeof errorIndex !== 'undefined') {
        rowset = rowsets[errorIndex]
        editor.error = rowset.error
        editor.showError = true
      } else {
        editor.error = null
        editor.showError = false

        // Determine what the batch policy is for displaying a rowset for a multi-query batch.
        if (rowsets.length > 1) {
          const queryBatchPolicy = this.$store.get('settings/editor@queryBatchPolicy')
          if (queryBatchPolicy === 'first-with-rows') {
            const firstWithRows = rowsets.find(r => r.rows && r.rows.length > 0)
            if (firstWithRows) {
              rowset = firstWithRows
              editor.showRowsetDetail = `.  First statement with results shown (${firstWithRows.index + 1}).`
            }
          } else if (queryBatchPolicy === 'last-with-rows') {
            const lastWithRows = new Array(...rowsets).reverse().find(r => r.rows && r.rows.length > 0)
            if (lastWithRows) {
              rowset = lastWithRows
              editor.showRowsetDetail = `.  Last statement with results shown (${lastWithRows.index + 1}).`
            }
          } else if (queryBatchPolicy === 'last') {
            rowset = rowsets[rowsets.length - 1]
            editor.showRowsetDetail = '.  Last statement shown.'
          } else {
            editor.showRowsetDetail = '.  First statement shown.'
          }
        }
      }

      // Set editor state.
      editor.rowset = Object.freeze(rowset)
      editor.rowsets = Object.freeze(rowsets)
    },

    async resumeResponse() {
      const { selectedInstanceId } = this

      // If we have a request id, let's see if its still active!
      if (!!selectedInstanceId && !!this.editor.settings.requestId) {
        const requestId = this.editor.settings.requestId
        this.editor.settings.requestId = null
        const active = await databaseObjectService.requestActive(selectedInstanceId, requestId)
        if (active) {
          // Resume polling.
          try {
            this.createPing()
            this.editor.loading = true
            await this.pollResponse(Promise.resolve({ requestId }), 1000, this.editor)
          } catch (e) {
            // Poll response failed; not much we can do other than set state.
            this.editor.settings.requestId = null
            console.log('Could not resume poll for previous query request')
            console.error(e)
          } finally {
            this.editor.loading = false
            this.destroyPing()
          }
        }
      }
    },

    uncolumnarize(rowset) {
      // We can detect if backend converted to columnar format by testing that rows are missing and every column has data.length > 0
      if ((!rowset.rows || !rowset.rows.length) &&
          rowset.columns?.length > 0 &&
          rowset.columns.every(c => c.data?.length > 0)) {
        // Make the array for all rows, respecting materializing max rows (rowsetLimit).
        const rowCount = Math.min(rowsetLimit, rowset.columns[0].data.length)
        rowset.rows = new Array(rowCount)

        // Make a prototype row.  This lets the JS compiler/optimizer "see" the data structure to optimize it.
        const prototypeRow = {}
        rowset.columns.forEach((column) => {
          prototypeRow[column.name] = null
        })

        // Stride once per row; all columns are uniform data length.
        for (let rowIndex = 0; rowIndex < rowCount; ++rowIndex) {
          const row = Object.assign({}, prototypeRow)
          rowset.columns.forEach((column) => {
            row[column.name] = column.data[rowIndex]
          })
          rowset.rows[rowIndex] = row
        }
      }

      // Cap rows given.
      if (rowset.rows.length > rowsetLimit) {
        this.$dialogs.warning(`Note: the rowset limit was exceeded (${rowset.rows.length}).  Yellowbrick Manager will only display the first ${rowsetLimit} rows.`)
        rowset.rows = rowset.rows.slice(0, rowsetLimit)
      }
    },

    runSelected($event) {
      if (!this.selected) {
        return
      }
      this.run($event, true)
    },

    async runWrapper($event) {
      this.run($event, !!this.selected)
    },

    ping() {
      const tagsRE = new RegExp(`ym:${this.defaultRole}:tab:${this.editor.settings.key}`)
      const thisQuery = tasksService.taskState.tasks.find(q => String(q.tags).match(tagsRE))
      this.canCancelQuery = !!thisQuery
      this.editor.queryEnd = +new Date()
    },

    focus() {
      if (this.$refs.editor) {
        this.$refs.editor.focus()
      }
    },

    change(sql) {
      if (!sql || typeof sql !== 'string' || (sql instanceof Event)) {
        return // native event leaked in here
      }
      this.editor.settings.sql = sql
      this.$emit('change', this.editor.settings)
    },

    async cancel() {
      if (!this.editor.loading) {
        return
      }
      try {
        // Be sure.
        false && await this.$dialogs.confirm('Are you sure you want to cancel this query?')

        this.$nextTick(async () => {
          // Find the query via the tasks service and ask it to cancel.
          const tagsRE = new RegExp(`ym:${this.defaultRole}:tab:${this.editor.settings.key}`)
          const thisQuery = tasksService.taskState.tasks.find(q => String(q.tags).match(tagsRE))
          if (thisQuery) {
            this.$track('query.run.cancel')
            const result = await tasksService.cancel(thisQuery)
            if (result) {
              this.editor.error = 'Your query was cancelled.'
              this.editor.showError = true
            }
          } else {
            this.$dialogs.warning('Could not locate the running query to cancel.', false, 3000)
          }
        })
      } catch (ignore) {}
    },

    query(event) {
      this.$emit('query', event)
    },

    explore({ databaseName, schemaName, tableName, path }) {
      const editor = reactive({
        settings: {
          database: databaseName,
          searchPath: [schemaName],
          role: this.editor.settings.role
        },
        rowset: null,
        path,
        batch: 0
      })
      // NB: don't use ${this.limit} here, as we want this to open quickly.
      this.runQuery(`SELECT * FROM ${path} LIMIT 1000`, editor)
      this.exploreTableTitle = `Explore Data: ${path}`
      this.explorer = editor
    },

    async more() {
      const editor = { settings: this.explorer.settings, rowset: null }
      await this.runQuery(`SELECT * FROM ${this.explorer.path} LIMIT 1000 OFFSET ${this.explorer.rowset.rows.length}`, editor)
      const newExplorer = functions.copyDeep(this.explorer)
      newExplorer.rowset.rows.splice(newExplorer.rowset.rows.length, 0, ...editor.rowset.rows)
      newExplorer.batch++
      this.explorer = newExplorer
    },

    showTable({ tableId, tableName }) {
      this.tableId = tableId
      this.tableName = tableName
    },

    disconnect() {
      this.$track('query.disconnect')
      const instanceId = this.editor.settings.instance
      this.editor.rowset = null
      this.editor.settings.instance = null
      this.editor.settings.database = null
      this.editor.settings.database_id = null
      instanceId && databaseObjectService.evict(instanceId)
    },

    executeEvent($event) {
      const { id, payload } = $event
      this.execute(id, payload)
    },

    execute(id, payload) {
      this.$track('query.execute.' + id)
      if (id === 'run') {
        this.run({})
      } else if (id === 'run.selected') {
        this.runSelected({})
      } else if (id === 'run.cancel') {
        this.cancel()
      } else if (id.match(/^file/)) {
        this.$emit('execute', id)
      } else if (id === 'yb.action.search') {
        this.$refs.tree.search()
      } else if (id === 'yb.action.history') {
        this.history = !this.history
      } else if (id === 'yb.action.fullscreen') {
        this.$emit('execute', id)
      } else if (id === 'yb.action.zoom') {
        this.editor.settings.zoomed = !this.editor.settings.zoomed
      } else if (id === 'yb.action.schemaBrowser') {
        this.editor.settings.schemaBrowser = !this.editor.settings.schemaBrowser
      } else {
        this.$refs.editor.execute(id, payload)
      }
    },

    select(verb, item) {
      if (verb === 'item') {
        const index = this.editor.settings.searchPath.indexOf(item)
        if (index >= 0) {
          this.editor.settings.searchPath.splice(index, 1)
        } else if (!this.editor.settings.searchPath.includes(item)) {
          this.editor.settings.searchPath.push(item)
        }
      } else {
        while (this.editor.settings.searchPath.length) {
          this.editor.settings.searchPath.pop()
        }
        if (verb === 'public') {
          this.editor.settings.searchPath.push('public')
        } else if (verb === 'default') {
          this.editor.settings.searchPath.push('default')
        } else if (verb === 'all') {
          this.allowedSchemas
            .filter(s => s.database_id === this.editor.settings.database_id)
            .forEach((s) => {
              this.editor.settings.searchPath.push(s.name)
            })
        }
      }
    },

    historyRun(sql) {
      this.historyEdit(sql)
      this.run()
    },

    historyEdit(sql) {
      this.execute('editor.action.selectAll')
      this.execute('paste', { text: sql })
      this.editor.rowset = null
      this.editor.rowsets = null
      if (this.$refs.tabsRef) {
        this.$refs.tabsRef.selectedIndex = 0
      }
      this.history = false
    },

    historyOpen(sql) {
      this.$emit('query', { sql })
      this.history = false
    },

    samples() {
      this.$root.showSampleCatalog()
    },

    updateZoom() {
      if (this.editor.settings.zoomed) {
        this.editor.settings.zoomSizes = [
          [...this.editor.settings.splitH],
          [...this.editor.settings.splitV]
        ]
        this.editor.settings.splitH[0] = 0
        this.editor.settings.splitH[1] = 100
        this.editor.settings.splitV[0] = 100
        this.editor.settings.splitV[1] = 0
      } else {
        if (this.editor.settings.zoomSizes) {
          if (this.editor.settings.zoomSizes[0][0] < 10) {
            this.editor.settings.zoomSizes[0] = [10, 90]
          }
          if (this.editor.settings.zoomSizes[1][1] < 10) {
            this.editor.settings.zoomSizes[1] = [90, 10]
          }
          this.editor.settings.splitH[0] = this.editor.settings.zoomSizes[0][0]
          this.editor.settings.splitH[1] = this.editor.settings.zoomSizes[0][1]
          this.editor.settings.splitV[0] = this.editor.settings.zoomSizes[1][0]
          this.editor.settings.splitV[1] = this.editor.settings.zoomSizes[1][1]
        } else {
          this.editor.settings.splitH[0] = 30
          this.editor.settings.splitH[1] = 70
          this.editor.settings.splitV[0] = 33
          this.editor.settings.splitV[1] = 66
        }
        this.editor.settings.zoomSizes = null
      }
    },

    maybeZoom() {
      if (!!this.editor.settings.zoomed && !!this.editor.settings.zoomSizes) {
        this.editor.settings.splitH[0] = 0
        this.editor.settings.splitH[1] = 100
        this.editor.settings.splitV[0] = 100
        this.editor.settings.splitV[1] = 0
      }
    },

    useCluster(name) {
      return `USE CLUSTER "${name}"`
    },
    setRole(name) {
      return `SET ROLE "${name}"`
    },
    setSearchPath(name) {
      return `SET SEARCH_PATH TO "${name}"`
    }
  }
}

</script>

<style scoped lang="postcss">

.settings-bar {
  @apply pl-2 py-2 flex flex-row items-center select-none bg-yb-gray-faint border-yb-gray-faint dark:border-yb-gray-medium border-b dark:bg-yb-gray-mediumer;
}

.slide-history-enter-active {
  transition: all .5s ease;
}
.slide-history-leave-active {
  transition: all .5s cubic-bezier(1, 0.25, 0.5, 1);
}
.slide-history-enter,
.slide-history-leave-to {
  transform: translateX(100%) translateY(-100%);
  opacity: 50%;
}

</style>
