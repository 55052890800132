<template>
  <div>
    <vue-context ref="menu" class="yb-v-context">
      <div v-if="false" class="yb-menu-heading">
        <yb-icon class="yb-button-icon" icon="file-details" />
        Details
      </div>
      <li v-if="false">
        <a class="indent" @click.prevent="open">View Details</a>
      </li>
      <div class="yb-menu-heading">
        <yb-icon class="yb-button-icon" icon="action" />
        Actions
      </div>
      <li>
        <a v-if="!isConnected" class="indent" @click.prevent="connect">
          Connect
        </a>
      </li>
      <li>
        <a v-if="isConnected" class="indent" @click.prevent="disconnect">
          Disconnect
        </a>
      </li>
    </vue-context>
  </div>
</template>

<script>
import YbEditorMixin from './EditorMixin'
import VueContext from '@/lib/vue-context/js'

export default {
  components: {
    VueContext
  },
  mixins: [YbEditorMixin],
  data() {
    return {
      item: null
    }
  },
  computed: {
    isConnected() {
      return this.item && this.editor.settings.database === this.item.name
    }
  },
  methods: {
    menu(item, $event) {
      this.item = item
      window.setTimeout(() => this.$refs.menu.open($event), 100)
    },

    closeMenu() {
      this.$refs.menu && this.$refs.menu.close()
    },

    connect() {
      this.editor.settings.database = this.item.name
      this.editor.settings.searchPath = ['public']
    },

    disconnect() {
      this.editor.settings.database = null
    }
  }
}
</script>
