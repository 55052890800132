<template>
  <div class="yb-view select-text">
    <div class="yb-view-header">
      <h5 class="w-full yb-bg-pane p-2">
        <div class="flex items-center content-center">
          <yb-icon class="yb-button-icon" icon="schema" />
          <span class="font-semibold">{{ name }}</span>
          <span v-yb-clipboard.text="id" v-tooltip="'Copy schema id to clipboard'" class="circle cursor-pointer text-xs ml-1.5">ID</span>
        </div>
        <div class="flex flex-row justify-between items-center">
          <p class="text-sm">
            <span v-show="!detailLoading">
              <span v-if="detailError">(Error retrieving details)</span>
              <span v-else-if="!rows && !size" />
              <span v-else>
                <span v-if="rows">{{ humanizeNumber(rows, 0) }} rows</span>
                <span v-if="rows && size">, {{ bytes(size) }} (compressed)</span>
                <span v-if="rows && uncompressed_bytes">, {{ bytes(uncompressed_bytes) }} (uncompressed)</span>
              </span>
            </span>
          </p>
          <div class="flex space-x-2 self-end uppercase text-xs">
            <a href="#" @click="menu">
              <yb-dots />
            </a>
          </div>
        </div>
      </h5>
    </div>
    <div class="yb-view-content p-2">
      <div v-if="!detailLoading && model && empty">
        There are no objects contained in this schema.
      </div>
      <div v-else-if="!detailLoading && model">
        <p v-for="(objectType, index) in objectTypes" :key="index">
          <span v-if="counts[objectType.type] > 1">
            {{ counts[objectType.type] }} {{ objectType.label }}s
          </span>
          <span v-if="counts[objectType.type] == 1">
            One {{ objectType.label }}
          </span>
          <span v-if="counts[objectType.type] == 0 && false">
            No {{ objectType.label }}s
          </span>
        </p>
      </div>
      <div v-else-if="detailLoading && !detailError">
        <yb-loading class="self-center text-center m-5" />
      </div>
    </div>
  </div>
</template>

<script>
import YbEditorMixin from './EditorMixin'
import { jolokiaService, mbeans } from '@/services'
import Schema from '@/models/Schema'
import { humanizeNumber, bytes } from '@/filters'

export default {
  mixins: [YbEditorMixin],
  props: {
    instanceId: String,
    name: String,
    id: Number,
    databaseId: Number,
    databaseName: String
  },
  data() {
    return {
      detailLoading: true,
      detailError: false,
      rows: 0,
      uncompressed_bytes: 0,
      size: 0
    }
  },
  computed: {
    model() {
      return !this.detailError && Schema.query().whereId([this.instanceId, this.databaseId, this.id]).first()
    },
    counts() {
      return this.model.counts
    },
    empty() {
      return Object.values(this.counts).reduce((v, sum) => v + sum, 0) === 0
    }
  },
  watch: {
    id() {
      this.update()
    }
  },
  mounted() {
    this.update()
  },
  methods: {
    humanizeNumber,
    bytes,
    async update() {
      try {
        const storageSql = `
          select sum(rows_columnstore) as rows_columnstore, sum(rowstore_row_count) as rows_rowstore, sum(compressed_bytes) as compressed_bytes, sum(uncompressed_bytes) as uncompressed_bytes
          from (
          select
            t.table_id,
            t.rowstore_row_count,
            case
              when t.distribution = 'replicated' then
                stats.rows_columnstore_max + 0
              else
                stats.rows_columnstore_sum + 0
            end as rows_columnstore,
            case
              when t.distribution = 'replicated' then
                stats.compressed_bytes_max + 0
              else
                stats.compressed_bytes_sum + 0
            end as compressed_bytes,
            case
              when t.distribution = 'replicated' then
                stats.uncompressed_bytes_max + 0
              else
                stats.uncompressed_bytes_sum + 0
            end as uncompressed_bytes
          from sys.table t
          left outer join (
            select
              table_id,
              sum(rows_columnstore) as rows_columnstore_sum,
              max(rows_columnstore) as rows_columnstore_max,
              sum(compressed_bytes) as compressed_bytes_sum,
              max(compressed_bytes) as compressed_bytes_max,
              sum(uncompressed_bytes) as uncompressed_bytes_sum,
              max(uncompressed_bytes) as uncompressed_bytes_max
            from sys.table_storage
            group by 1
          ) stats on stats.table_id = t.table_id
          where t.database_id = ${this.databaseId} 
          and t.schema_id = ${this.id}
        ) sums`

        this.detailLoading = true
        this.detailError = false
        const results = await jolokiaService.execute(mbeans.datasources, 'queryEx', [this.databaseName, storageSql, null, 0])

        if (results?.rows && results.rows.length > 0) {
          this.size = results.rows[0].compressed_bytes
          this.uncompressed_bytes = results.rows[0].uncompressed_bytes
          this.rows = results.rows[0].rows_rowstore + results.rows[0].rows_columnstore
        }
      } catch (e) {
        this.detailError = true
        if (!this.editor.showError) {
          this.editor.error = e
          this.editor.showError = true
        }
      } finally {
        this.detailLoading = false
      }
    },

    menu($event) {
      this.$emit('menu', $event)
    }
  }
}
</script>
