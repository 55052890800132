<template>
  <div class="w-full h-full overflow-hidden yb-view p-0">
    <div v-show="!loading" class="yb-view-header mb-2 space-y-1">
      <div class="flex items-end justify-between">
        <div class="flex flex-row space-x-2">
          <button
            class="yb-button text-sm yb-button-primary-base hover:bg-yb-selection-light border uppercase font-semibold"
            :disabled="!query || !query.query_id"
            :class="{'yb-border-content': !query || !query.query_id, 'border-yb-validate': query && query.query_id }"
            @click="fire('run')"
          >
            <yb-icon class="yb-button-icon-md" icon="play" />
            Run
          </button>
          <button
            class="yb-button border yb-border-content rounded-sm hover:bg-yb-selection-light"
            :disabled="!query || !query.query_id"
            @click="fire('edit')"
          >
            <yb-icon class="yb-button-icon" icon="edit" />
            Open/Replace
          </button>
          <button
            class="yb-button border yb-border-content rounded-sm hover:bg-yb-selection-light"
            :disabled="!query || !query.query_id"
            @click="fire('open')"
          >
            <yb-icon class="yb-button-icon" icon="plus-circle" />
            Open in New Tab
          </button>
        </div>

        <div class="flex flex-1 items-end justify-end space-x-2">
          <div class="flex flex-col justify-start">
            <label class="text-xs r-4 whitespace-nowrap mb-0.5">Show queries from</label>
            <yb-button-group
              v-model="show"
              label=""
              class="text-sm whitespace-nowrap"
              :vertical="false"
              btn-down="btn-yellow"
              type="id"
              :options="[
                {
                  id: 'tab',
                  label: 'Current Tab'
                },
                {
                  id: 'any',
                  label: 'All Tabs'
                },
              ]"
            />
          </div>

          <div class="flex flex-col justify-end">
            <label v-show="itemCount" class="text-xs text-right whitespace-nowrap mb-0.5">Displaying {{ itemCount }} queries</label>
            <yb-search-input
              v-model="searchHistory"
              placeholder="Search history"
              input-classes="w-full yb-input-base px-2 py-0.5 rounded"
              wrapper-classes="w-40"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="yb-view-content yb-bg-content pb-2">
      <yb-grid
        v-show="!loading"
        v-bind="$productionDataPrivate"
        ref="grid"
        class="ag-theme-alpine h-full w-full"
        :rows="getRows"
        :column-defs="columnDefs"
        :search-expression="searchHistory"
        data-test-id="query-history-grid"
        @ready="ready"
        @load="load"
        @select="select"
        @dblclick="runSelected"
      />
    </div>
  </div>
</template>

<script>
import * as YbCellRenderers from '@/components/YbGridCellRenderers'
import { tasksService } from '@/services'
import queryStatService from '@/services/queryStatService'

export default {
  inject: ['editor'],
  props: {
    id: String,
    instance: Object,
    database: String
  },
  data() {
    return {
      show: 'tab',
      columnDefs: [
        // { headerName: 'ID', field: 'query_id', flex: 0, minWidth: 75 },
        {
          headerName: '?',
          field: 'error_code',
          flex: 0,
          minWidth: 50,
          maxWidth: 50,
          sortable: true,
          cellRenderer: YbCellRenderers.YbGridErrorCodeCellRenderer,
          cellRendererParams: {
            tooltip: 'error_message'
          },
          cellClass: 'grid-center'
        },
        {
          headerName: 'Date', field: 'submit_time', minWidth: 200, maxWidth: 200, flex: 0, cellRenderer: YbCellRenderers.YbGridTimestampCellRenderer
          // colSpan: function (params) {
          //   if (params.type === 'summary') {
          //     return 3;
          //   } else {
          //     return 1;
          //   }
          // }
        },
        { headerName: 'Duration', field: 'total_ms', minWidth: 50, maxWidth: 125, flex: 0, cellRenderer: YbCellRenderers.YbGridDurationCellRenderer },
        {
          headerName: 'Query',
          field: 'query_text',
          minWidth: 400,
          flex: 10,
          cellRenderer: YbCellRenderers.YbGridQueryTextCellRenderer,
          cellClass: 'grid-center'
        }
      ],
      searchHistory: '',
      itemCount: 0,
      query: {
        query_id: 0,
        plan_id: null,
        query_text: null,
        query_text_length: 0
      }
    }
  },
  computed: {
    loading() {
      return this.$refs.grid?.loading
    }
  },
  watch: {
    show(_) {
      this.$refs.grid.reset()
    },
    searchHistory(_) {
      this.$refs.grid.reset()
    }
  },
  methods: {
    getRows(limit, offset, orderByModel, filterModel) {
      const username = this.$store.get('global/settings@user.email')
      let andWhere = ''
      if (this.show === 'tab') {
        andWhere += `AND tags LIKE 'ym:${username}:tab:${this.id}'\n`
      } else {
        andWhere += `AND tags LIKE 'ym:${username}%'\n`
      }
      if (!!this.searchHistory && !!this.searchHistory.trim()) {
        andWhere += `AND query_text ILIKE '%${this.searchHistory}%'\n`
      }
      const orderBys = orderByModel.map((o) => {
        return `${o.colId} ${o.sort}`
      }).join(', ')
      const orderBy = (!!orderBys && `ORDER BY ${orderBys}`) || null
      return tasksService.queriesForInstance(this.instance, this.database, 'historical', andWhere, limit, offset, orderBy)
    },
    ready() {
      this.$refs.grid.gridColumnApi.applyColumnState({
        state: [
          {
            colId: 'submit_time',
            sort: 'desc'
          }
        ],
        defaultState: { sort: null }
      })
    },
    load() {
      this.itemCount = this.$refs.grid?.itemCount
    },
    select(items) {
      const item = (items && items[0]) || null
      if (!item || this.query === item || this.query?.query_id === item?.query_id) {
        this.query.query_id = 0
      } else {
        Object.assign(this.query, item)
        this.editor.rowset = {
          execId: item?.query_id,
          rows: [{}]
        }
      }
    },
    runSelected(items) {
      const item = (items && items[0]) || null
      if (item?.query_id) {
        this.fire('run')
      }
    },
    async retrieveQueryText() {
      if (this.query?.query_id) {
        if (this.query.query_text.length !== this.query_text_length) {
          this.query.query_text = await queryStatService.getQueryText(this.query.query_id)
          this.query.query_text_length = this.query.query_text.length
        }
      }
    },
    async fire(verb) {
      await this.retrieveQueryText()
      this.$emit(verb, this.query.query_text)
    }
  }
}
</script>

<style lang="postcss">
div .ag-theme-alpine .ag-row .ag-cell .smc-highlight {
  @apply whitespace-nowrap;
  word-wrap: break-word;
}
</style>

<style lang="postcss" scoped>
:deep(.grid-center) {
  @apply flex flex-row flex-nowrap items-center;
}
</style>
