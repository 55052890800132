<template>
  <div>
    <vue-context ref="menu" class="yb-v-context">
      <div v-if="false" class="yb-menu-heading">
        <yb-icon class="yb-button-icon" icon="file-details" />
        Details
      </div>
      <li v-if="false">
        <a class="indent" @click.prevent="open">View Details</a>
      </li>
      <div class="yb-menu-heading">
        <yb-icon class="yb-button-icon" icon="file-search" />
        Explore
      </div>
      <li>
        <a class="indent" @click.prevent="showRelationships = true">Relationships</a>
      </li>

      <div class="yb-menu-heading">
        <yb-icon class="yb-button-icon" icon="action" />
        Actions
      </div>
      <li>
        <a v-if="!inSearchPath" class="indent" @click.prevent="addSearchPath">
          Add to Search Path
        </a>
      </li>
      <li>
        <a v-if="inSearchPath" class="indent" @click.prevent="removeSearchPath">
          Remove from Search Path
        </a>
      </li>
    </vue-context>
    <transition name="drawer">
      <yb-modal
        v-if="showRelationships"
        show
        :title="'Relationships for Schema ' + item.name"
        icon="schema"
        type="form"
        layout="drawer"
        dialog-classes=""
        drawer-width="w-2/3"
        data-test-id="schema-relationships"
        @close="showRelationships = false"
      >
        <yb-detail-relationships :database="item.database_name" :schema-id="item.id" />
      </yb-modal>
    </transition>
  </div>
</template>

<script>
import YbEditorMixin from './EditorMixin'
import YbDetailRelationships from './DetailRelationships.vue'
import VueContext from '@/lib/vue-context/js'

export default {
  components: {
    VueContext,
    YbDetailRelationships
  },
  mixins: [YbEditorMixin],
  data() {
    return {
      item: null,
      showDetails: false,
      showRelationships: false
    }
  },
  computed: {
    inSearchPath() {
      return this.item && this.editor.settings.searchPath.includes(this.item.name)
    }
  },
  methods: {
    menu(item, $event) {
      this.item = item
      window.setTimeout(() => this.$refs.menu.open($event), 100)
    },

    closeMenu() {
      this.$refs.menu && this.$refs.menu.close()
    },

    addSearchPath() {
      this.editor.settings.searchPath.push(this.item.name)
    },

    removeSearchPath() {
      const index = this.editor.settings.searchPath.indexOf(this.item.name)
      if (index >= 0) {
        this.editor.settings.searchPath.splice(index, 1)
      }
    }
  }
}
</script>
