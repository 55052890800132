<template>
  <div class="yb-view select-text">
    <div class="yb-view-header">
      <h5 class="w-full yb-bg-pane p-2">
        <div class="flex items-center content-center">
          <yb-icon class="yb-button-icon" icon="table" />
          <span class="font-semibold">{{ name }}</span>
          <span v-yb-clipboard.text="id" v-tooltip="'Copy table id to clipboard'" class="circle cursor-pointer text-xs ml-1.5">ID</span>
        </div>
        <div class="flex flex-row justify-between items-center">
          <p class="text-sm">
            <span v-show="!detailLoading">
              <span v-if="detailError">(Error retrieving details)</span>
              <span v-else-if="!rows && !size" />
              <span v-else>
                <span v-if="rows">{{ humanizeNumber(rows, 0) }} rows</span>
                <span v-if="rows && size">, {{ bytes(size) }} (compressed)</span>
                <span v-if="rows && details.columnstore_uncompressed_bytes">, {{ bytes(details.columnstore_uncompressed_bytes) }} (uncompressed)</span>
              </span>
            </span>
          </p>
          <div class="flex space-x-2 self-end uppercase text-xs">
            <a href="#" data-test-id="detail-table-dots" @click="menu">
              <yb-dots />
            </a>
          </div>
        </div>
      </h5>
      <div v-if="!detailLoading" class="p-2">
        <ul class="list-none list-inside pb-2 text-sm">
          <li v-if="details.distribution === 'replicated'">
            <div v-tooltip="'Table is replicated'" class="circle cursor-pointer">
              D
            </div>Distribute <span class="font-semibold">replicated</span>
          </li>
          <li v-else-if="details.distribution === 'random'">
            <div v-tooltip="'Table is using random distribution'" class="circle cursor-pointer">
              D
            </div>Distribute <span class="font-semibold">random</span>
          </li>
          <li v-else>
            <div v-tooltip="'Table is using hash distribution'" class="circle cursor-pointer">
              D
            </div>Distribute {{ details.distribution }} on <span class="font-semibold">{{ details.distribution_key }}</span>
          </li>

          <li v-if="!!details.sort_key">
            <div v-tooltip="'Table is using sort column'" class="circle cursor-pointer">
              S
            </div>Sort on <span class="font-semibold">{{ details.sort_key }}</span>
          </li>

          <li v-if="!!details.cluster_keys">
            <div v-tooltip="'Table is using cluster columns'" class="circle cursor-pointer">
              C
            </div>Cluster on <span class="font-semibold">{{ details.cluster_keys }}</span>
          </li>

          <li v-if="!!details.partition_keys">
            <div v-tooltip="'Table is partitioned'" class="circle cursor-pointer">
              P
            </div>Partition on <span class="font-semibold">{{ details.partition_keys }}</span>
          </li>
        </ul>
        <hr class="py-1">
      </div>
      <div v-else-if="detailLoading && !detailError">
        <yb-loading class="self-center text-center m-5" />
      </div>
    </div>
    <div v-if="!detailLoading" class="yb-view-content">
      <div class="overflow-auto h-full w-full px-2">
        <table class="w-full text-sm">
          <thead>
            <th class="text-left font-semibold">
              Column
            </th>
            <th class="text-left font-semibold" colspan="2">
              Type
            </th>
          </thead>
          <tr v-for="(c, index) in columns" :key="index" class="w-full border-none">
            <td width="100%">
              {{ c.name }}
            </td>
            <td v-tooltip="'Column type: ' + c.type" width="80px" class="whitespace-nowrap select-none cursor-pointer">
              <span v-if="c.type.match(/num|dec|small|tiny|int|float|real|double/i)">123</span>
              <span v-else-if="c.type.match(/char|vary/i)">ABC</span>
              <span v-else-if="c.type.match(/bool/i)">BOOL</span>
              <span v-else-if="c.type.match(/date/i)">DATE</span>
              <span v-else-if="c.type.match(/timestamp/i)">TS</span>
              <span v-else-if="c.type.match(/uuid/i)">UUID</span>
              <span v-else>{{ c.type.substring(0, 5).toUpperCase() }}</span>
            </td>
            <td width="30px" class="select-none">
              <div class="flex flex-row flex-nowrap items-center space-x-0.5">
                <div v-if="c.nullable" v-tooltip="'Column is nullable'" class="circle cursor-pointer">
                  N
                </div>
                <div v-if="c.primaryKey" v-tooltip="'Column is primary key'" class="circle cursor-pointer">
                  K
                </div>
              </div>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import YbEditorMixin from './EditorMixin'
import YbTableMixin from './TableMixin'
import { humanizeNumber, bytes } from '@/filters'

export default {
  mixins: [YbEditorMixin, YbTableMixin],
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  computed: {
    rows() {
      return this.details?.rowstore_row_count + this.details?.columnstore_row_count
    },
    size() {
      return this.details?.rowstore_bytes + this.details?.columnstore_bytes
    }
  },
  watch: {
    id() {
      this.update()
    }
  },
  mounted() {
    this.update()
  },
  methods: {
    humanizeNumber,
    bytes,
    menu($event) {
      this.$emit('menu', $event)
    }
  }
}
</script>
